.footer {
    background: #000000;
    color: #FFFFFF;
    margin-top: 90px;
}
.footer a {
    color: #FFFFFF;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}
.footer a:hover,.footer a:visited,footer a:active {
    color: #FFFFFF;
}
.footer .logo {
    margin-bottom: 77px;
    display: flex;
}
.footer .logo svg {
    width: 64px;
    height: 33px;
}
.footer .footerColumnTitle {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 8px;
}
.footer .footerColumn:not(:last-child) {
    padding-right: 10px;
    padding-bottom: 25px;
    margin-bottom: 98px;
}
.footer a {
    color: #FFFFFF;
}
.footer .container {
    display: flex;
    max-width: 1440px;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
    padding:0px 103px;
    padding-top: 64px;
    padding-bottom: 128px;
}
.footer .footerBottom {
    display: flex;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0px 103px;
    padding-bottom: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer .footerBottom .footerBottomRight {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer .footerBottom .info {
    font-size: 12px;
    line-height: 14px;
}
.footer .footerBottom .info:not(:last-child) {
    margin-right: 48px;
}
.footer .footerBottom .info a {
    background: none !important;
    color: #FFFFFF !important;
    padding: 0px !important;
    font-weight: normal !important;
    font-family: Archivo !important;
}
.footer .footerColumn .socials {
    display: flex;
    justify-content: flex-end;
}
.footer .footerColumn .socials svg {
    color: #FFFFFF;
    width: 28px;
    height: 28px;
}
.footer .footerColumn .socials a {
    cursor: pointer;
    display: inline-block;
}
.footer .footerColumn .socials a:not(:last-child) {
    margin-right: 18px;
}
.footer .footerColumn .footerColumn-title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 8px;
}
.footer .footerColumn .info {
    font-size: 12px;
    line-height: 14px;
    margin-top: 4px;
    font-family: Archivo !important;
    font-weight: normal !important;
}

.footer .footerColumn .footerColumnSubtitle,.footer .footerColumn .footerColumnInfo{
    margin-top: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}
.footer .footerColumn .footerColumnInfo {
    margin-top: 2px;
    font-weight: normal;
}
.footer svg path{
    fill: #FFFFFF;
}
.footer .footerColumn:nth-child(1) {
    width: 25%;
}
.footer .footerColumn:nth-child(2) {
    width: 18%;
    min-width: 120px;
}
.footer .footerColumn:nth-child(3) {
    width: 17%;
    min-width: 145px;
}
.footer .footerColumn:nth-child(4) {
    text-align: right
}

@media (max-width: 960px) {
    .footer .footerColumn .socials {
        justify-content: flex-start;
    }
    .footer .footerColumn:not(:last-child) {
        padding-bottom: 0px;
    }
}

@media (max-width: 720px) {

    .footer .container {
        padding: 0px 103px;
        padding-top: 64px;
        padding-bottom: 132px;
    }
    .footer .footerColumn:not(:last-child) {
        padding-bottom: 0px;
    }
    .footer .footerBottom {
        align-items: start;
        padding: 0px 103px;
        padding-bottom: 72px;
        flex-direction: column;
        justify-content: space-between;
    }
    .footer .footerBottom .footerBottomLeft {
        margin-bottom: 64px;
    }

    .footer .footerColumn:first-child {
        width: 100% !important;
        margin-bottom: 0px;

    }

    .footer .footerColumn:nth-child(2) {
        width: 48%;
    }

    .footer .footerColumn:nth-child(3) {
        width: 52% !important;
    }

    .footer .logo {
        margin-bottom: 48px !important;
    }
}
@media (max-width: 490px) {
    .footer .container,.footer .footerBottom  {
        padding: 0px 32px;
        padding-top: 64px;
        padding-bottom: 68px;
    }
}
@media (max-width: 390px) {
    .footer .footerColumn:nth-child(1),.footer .footerColumn:nth-child(2),.footer .footerColumn:nth-child(3),.footer .footerColumn:nth-child(4)  {
        width: 100% !important;
        min-width: auto;
    }
    .footerColumn:nth-child(3) {
        margin-top: 48px;
    }
    .footer .footerColumn:nth-child(2){
        margin-bottom: 0px;
    }
    .footer .footerColumn:nth-child(3){
        margin-top: 48px;
        margin-bottom: 100px;
    }
    .footer .container {
        padding: 0px 32px;
        padding-top: 64px;
        padding-bottom: 69px;
    }
    .footer .footerBottom {
        align-items: start;
        padding: 0px 32px;
        padding-bottom: 72px;
        flex-direction: column;
        justify-content: space-between;
    }
    .footer .footerBottom .footerBottomLeft {
        margin-bottom: 64px;
    }
}
