@import url(https://fonts.googleapis.com/css?family=Archivo:300,400,500,600,700&display=swap);
.button_button__2pFT2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 8px 36px;
  border-radius: 4px;
  font-size: 18px;
}

  .button_button__2pFT2.button_normal__1TG1J {
    border: 2px solid var(--color-light-primary);
  }

  .button_button__2pFT2.button_normal__1TG1J:hover,
    .button_button__2pFT2.button_normal__1TG1J:focus {
      border: 2px solid var(--color-highlight);
      color: var(--color-highlight);
    }

  .button_button__2pFT2.button_primary__2Z9E_ {
    color: var(--color-background-primary);
    background-color: var(--color-light-primary);
  }

  .button_button__2pFT2.button_primary__2Z9E_:hover,
    .button_button__2pFT2.button_primary__2Z9E_:focus {
      color: var(--color-background-primary);
      background-color: var(--color-highlight);
    }

  .button_button__2pFT2:disabled {
    cursor: not-allowed;
    background-color: var(--color-disable);
  }

  .button_button__2pFT2:disabled:hover,
    .button_button__2pFT2:disabled:focus {
      background-color: var(--color-disable);
    }

.radio_radio__3T1hU {
  display: inline-flex;
  align-items: center;
}

.radio_inner__3Sg9K {
  position: relative;
  -webkit-appearance: none;
          appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  /*background-color: var(--color-background-secondary);*/
  border: solid 1px #000;
  cursor: pointer;
}

.radio_inner__3Sg9K:hover,
  .radio_inner__3Sg9K:focus {
    border: solid 1px #ff6c2d;
  }

.radio_inner__3Sg9K:checked {
    border: solid 1px #ff6c2d;
    background-color: #ff6c2d;
  }

.radio_inner__3Sg9K:checked::after {
      content: '';
      display: block;
      width: 4px;
      height: 8px;
      position: absolute;
      top: 3px;
      left: 6px;
      border: 1px solid #fff;
      border-top: 0;
      border-left: 0;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
    }

.radio_text__1QOqJ {
  font-size: 12px;
  margin-left: 6px;
  margin-right: 25px;
}

.card_pricePoint__YHLRM {
  position: absolute;
  right: -11px;
  top: -7px;
  color: #ff6c2d;
}

.card_priceView__3Ty3Q {
  position: relative;
}

.card_smallCard__1qr6q {
  width: 134px !important;
  height: 165px !important;

  /*
  width: 134px !important;
  height: 165px !important;
  &Info {
     width: 134px !important;
  }
  */
}

.card_smallCardInfo__3mgT_ {
    width: 142px !important;
  }

.card_smallCardInfoChecked__1lyWc {
      padding-left: 4px;
      min-height: 60px !important;
    }

.card_smallCardInfoUnChecked__24Kl4 {
      padding-left: 4px;
      min-height: 60px !important;
    }

.card_card__1PvOV {
  cursor: pointer;
  position: relative;
  display: inline-flex;
  width: 212px;
  flex-direction: column;
  justify-content: center;
  height: 262px;
  background: #F6F8FD;
  border-radius: 8px;
}

.card_cardItem__RZ60b a:hover {
      color: #000000;
    }

.card_cardItemChecked__pAtK_ {
      height: 234px;
      width: 142px;
      background: #F4F4F4;
      border-radius: 11px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 4px;
    }

.card_cardItemUnChecked__1IauD {
      height: 234px;
      width: 142px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 4px;
    }

.card_cardInfo__1ZUfL {
    display: flex;
    margin-top: 12px;
    font-size: 12px;
    align-items: flex-start;
    flex-direction: column;
    min-height: 50px;
  }

.card_cardInfo__1ZUfL span:only-child {
      margin: 0;
    }

.card_cardId__3O645 {
    display: flex;
    align-items: center;
    margin-right: auto;
    padding: 3px 6px;
    padding-left: 0;
    border-radius: 2px;
    font-size: 14px;
    height: 14px;
    line-height: 14px;

    /*background-color: var(--color-background-secondary);*/
  }

.card_cardId__3O645 svg {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }

.card_cardCheck__2kNnR {
    position: absolute;
    left: 12px;
    top: 8px;
  }

.card_cardCheck__2kNnR svg {
      fill: none !important;
    }

.card_cardSale__3-Ona {
    font-size: 12px;
    right: 12px;
    top: 8px;
    height: 16px;
    line-height: 14px;
    padding: 0px 3px;
    border-radius: 12px;
    border: 1px solid #000;
    position: absolute;
  }

.card_cardLink__2sYlm {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

.card_cardImage__14Tgg {
  }

.card_cardLastPrice__3p9FK {
    position: relative;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #909090;
  }

.card_cardLastPriceUSD__2ek-2 {
      border: 0px;
    }

.card_cardPrice__21tNO {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

.card_cardPriceInner__2FB3d {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 212px;
    }

.card_cardPriceInnerSmall__2dTSy {
         display: flex;
         align-items: center;
         flex-wrap: wrap;
         width: 212px;
         width: 134px;
      }

.card_cardPriceETH__1reVH {
      font-size: 18px;
      line-height: 24px;
      margin-right: 4.5px;
    }

.card_cardPriceUSD__hDQNW {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 14px;
      color: #ff6c2d;
      border: 1px solid #ff6c2d;
      border-radius: 12px;
      height: 13px;
      padding: 7px 5px;
    }

@media (max-width: 968px) {
  .card_card__1PvOV {
    width: 160px;
  }

    .card_cardPriceETH__1reVH {
    }

    .card_cardPriceInner__2FB3d {
      width: 152px;
    }

    .card_cardInfo__1ZUfL {
      min-height: 38px;
    }
}

@media (max-width: 320px) {
  .card_card__1PvOV {
    width: 134px;
  }
}

.message_message__2VZVU {
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: var(--z-index-message);
  pointer-events: none;
}

  .message_message-notice__LHnRS {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 0px 24px;
    font-size: 18px;
    border-radius: 4px;
    color: #fff;
    background-color: #ff6c2d;
    border-radius: 15px;
    pointer-events: auto;
  }

  .message_message-notice__LHnRS.message_warning__22xZO {
      background-color: #ff6c2d;
      color: #fff;
      border-radius: 15px;
    }

  .message_message-notice__LHnRS .message_icon__2JxIk {
      display: none;
      width: 16px;
      height: 16px;
    }

  .message_message-notice__LHnRS .message_close__197bh {
      display: inline-flex;
      margin-left: 16px;
    }

  .message_message-notice__LHnRS .message_closeIcon__12uDM {
        width: 16px;
        height: 16px;
      }

  .message_message-notice-text__Bq05H {
      margin-left: 8px;
    }

  .message_message-notice__LHnRS.message_message-notice-enter__3ZpYI {
      opacity: 0;
    }

  .message_message-notice__LHnRS.message_message-notice-enter-active__3ssz5 {
      opacity: 1;
      transition: opacity 0.3s ease-in;
    }

  .message_message-notice__LHnRS.message_message-notice-exit__3kxfc {
      max-height: 100vh;
      opacity: 1;
    }

  .message_message-notice__LHnRS.message_message-notice-exit-active__3upkU {
      max-height: 0;
      opacity: 0;
      transition: opacity 0.3s ease-in, max-height 0.3s;
    }

.card_overview_container__1RkYs {
  display: flex;
  flex-direction: column;
}
.card_overview_summary__n2mqf {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.card_overview_purchased__3lIiV {
  font-weight: 600;
  font-size: 14px;
  color: #ff6c2d;
  text-decoration: underline;
}
.card_overview_trait__1GR6D {
  display: flex;
  /* width: 128px; */
  border: 2px solid #ff6c2d;
  border-radius: 11px;
  flex-direction: column;
  padding: 3px 7px;
}
.card_overview_traitInfo__3urKc {
    font-size: 18px;
  }
.card_overview_price__3sKGY {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}
.card_overview_priceTitle__ootcu {
    font-weight: 600;
    font-size: 14px;
  }
.card_overview_priceContainer__2s698 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 11px;
  }
.card_overview_card__30nzv {
  position: relative;
  display: flex;
  top: 0;
  width: 291px;
  height: 360px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #F6F8FD;
}
.card_overview_cardSale__Fj3Wt {
    font-size: 12px;
    right: 8px;
    top: 12px;
    height: 19px;
    line-height: 18px;
    padding: 0px 5px;
    border-radius: 12px;
    border: 1px solid #000;
    position: absolute;
  }
.card_overview_cardImage__Z5ONP {
    margin-bottom: 30px;
  }
.card_overview_cardImage__Z5ONP[src=''] {
    opacity: 0;
  }
.card_overview_cardImage__Z5ONP:after {
    content: '' ' ' attr(alt);
  }
.card_overview_cardPrice__3suso {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.card_overview_cardPriceInner__2eqmM {
      display: block;
    }
.card_overview_cardPriceETH__2yzbw {
      font-size: 18px;
      height: 20px;
      line-height: 20px;
    }
.card_overview_cardPriceUSD__1y0P5 {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #ff6c2d;
      border: 1px solid #ff6c2d;
      border-radius: 12px;
      height: 13px;
      margin-left: 10px;
      padding: 7px 5px;
    }

@media (max-width: 850px) {
  .card_overview_container__1RkYs {
    width: 100%;
  }
  .card_overview_card__30nzv {
    position: relative;
    width: 100%;
    height: 440px;
  }
}

@media (max-width: 320px) {
  .card_overview_container__1RkYs {
    width: 100%;
  }
  .card_overview_card__30nzv {
    position: relative;
    width: 100%;
    height: 380px;
  }
}

.card_list_list__29Cy0 {
  width: 100%;
  max-width: 1432px;
  flex: 1 1;
  margin: 0 auto;
}

  .card_list_listHeader__2kzZ3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }

  .card_list_listHeaderSearchInput__3Gyq- {
      width: 108px;
    }

  .card_list_listHeaderSearchInputLarge__1_xpt {
        width: 212px;
      }

.card_list_check__3Phxf {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.card_list_checkTitle__392QC {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
  }

.card_list_checkSelectAll__3RX9E {
    border: 1px solid #000000;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #000000;
    padding: 2.5px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
  }

.card_list_checkSelectAllDisabled__2vzvW {
      color: #909090;
      border: 1px solid #000000;
    }

.card_list_checkSelectAllSelected__311nx {
      border: 1px solid #FF6C2D;
      background: #FF6C2D;
      color: #FFFFFF;
    }

.card_list_checkClearAll__2mhaa {
    border: 1px solid #000000;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    padding: 2.5px 7px;
    display: flex;
    align-items: center;
    line-height: 15px;
    justify-content: center;
    color: #909090;
    cursor: pointer;
  }

.card_list_checkClearAllActive__1MrpM {
      color: #000000;
    }

.card_list_sum__1Nq8W {
  display: flex;
  font-family: Archivo;
  font-size: 20px;
}

.card_list_sort__DgQZw {
  display: flex;
}

.card_list_select__1x_eh:first-child {
  margin: 0 16px;
}

.card_list_select__1x_eh select {
  width: 212px;
  border: 0;
  border-bottom: 1px solid;
}

.card_list_cards__3AkXO {
  display: flex;
  justify-content: center;
  margin: 20px auto;
  flex-wrap: wrap;
  grid-gap: 24px;
  gap: 24px;
}

.card_list_cardsHeightLargeModal__3_gDK {
      height: 380px;
      overflow: auto;
    }

.card_list_cardsHeightSmallModal__37iuu {
      height: 390px;
      overflow: auto;
    }

.card_list_cardsSmall__3q__V {
    margin: 0 auto !important;
    margin-top: 16px !important;
    grid-gap: 4px 15px !important;
    gap: 4px 15px !important;
  }

.card_list_cardsNotFound__3o4Fd {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 380px;
    padding-top: 84px;
  }

.card_list_cardsNotFoundLarge__35pHg {
      min-height: 516px;
    }

.card_list_cardsNotFound__3o4Fd span {
      margin-top: 36px;
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      color: #000000;
    }

.card_list_cardsNotFound__3o4Fd svg {
      width: 72px;
      height: 72px;
      fill: none;
    }

.card_list_pagination__1ywOm {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.card_list_marketplace__1Rm5G .card_list_menu__3fHWM {
  display: none;
}

.card_list_card__2lWGD {
  cursor: inherit;
  position: relative;
  display: inline-flex;
  width: 212px;
  flex-direction: column;
  justify-content: center;
  height: 262px;
  background: #F6F8FD;
  border-radius: 8px;
}

.card_list_cardPointer__1a8S2 {
    cursor: pointer;
  }

.card_list_cardPagination__23BqU {
    padding-top: 10px;
    padding-bottom: 30px;
  }

.card_list_cardBreedInfo__cYw0r {
    font-size: 12px;
    color: #909090;
  }

.card_list_cardItem__2jXrz a:hover {
      color: #000000;
    }

.card_list_cardInfo__38TLg {
    display: flex;
    margin-top: 12px;
    font-size: 12px;
    align-items: flex-start;
    flex-direction: column;
    min-height: 50px;
  }

.card_list_cardInfo__38TLg span:only-child {
      margin: 0;
    }

.card_list_cardId__fb18i {
    display: flex;
    align-items: center;
    margin-right: auto;
    padding: 3px 6px;
    padding-left: 0;
    border-radius: 2px;
    font-size: 14px;
    line-height: 14px;
  }

.card_list_cardId__fb18i svg {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }

.card_list_cardLink__UtgJ9 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

@media (min-width: 1600px) {
  .card_list_list__29Cy0 {
    width: 100%;
    max-width: 100%;
    flex: 1 1;
    margin: 0 auto;
  }
}

@media (max-width: 968px) {
  .card_list_card__2lWGD {
    width: 152px;
    height: 188px;
  }
    .card_list_cardInfo__38TLg {
       width: 152px;
    }
  .card_list_cards__3AkXO {
    display: flex;
    justify-content: center;
    margin: 11px auto;
    flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px;
    height: 321px;
    overflow-y: scroll;
  }
    .card_list_cardsSmall__3q__V {
       height: 380px;
       overflow: auto;
    }
}
@media (max-width: 851px) {
  .card_list_open__2H6Uf {
    display: flex;
    flex-direction: column;
    padding-right: 0px;
  }

  .card_list_menu__3fHWM {
    display: flex !important;
    width: 32px;
    height: 32px;
    -webkit-user-select: none;
    user-select: none;
    z-index: var(--z-index-header);
    align-items: center;
    justify-content: flex-end;
  }

  .card_list_menu__3fHWM svg {
    width: 28px;
    height: 28px;
  }
    .card_list_filterRow__EXM85 {
      display: none;
      flex-direction: column;
      padding-right: 0px;
    }

    .card_list_filterBlock__2awc9 {
      margin-right: 0px;
      margin-bottom: 15px;
    }

    .card_list_filterBlock__2awc9:first-child {
      margin-bottom: 0px;
    }

  .card_list_sort__DgQZw {
    justify-content: space-between;
  }

  .card_list_inner__3APh0 {
    margin: 16px;
  }

  .card_list_split__1hqCH {
    margin-top: 15px;
  }
    .card_list_listHeader__2kzZ3 {
      flex-direction: column;
      margin-top: 25px;
    }

      .card_list_listHeaderSmall__3Fhj5 {
        flex-direction: row;
        margin-top: 0px;
      }

  .card_list_pagination__1ywOm {
    padding-top: 0px;
    margin-top: 28px !important;
    padding-bottom: 0px;
  }

  .card_list_select__1x_eh select {
    width: 100%;
  }

  .card_list_select__1x_eh:first-child {
    margin: 0px;
  }

  .card_list_select__1x_eh {
    margin: 0px;
    width: 47%;
  }
}

@media (max-width: 720px) {
      .card_list_listHeaderSmall__3Fhj5 {
        flex-direction: column;
      }

      .card_list_listHeaderSearchInput__3Gyq- {
        width: 100%;
      }

        .card_list_listHeaderSearchInputLarge__1_xpt {
          width: 100%;
        }

  .card_list_pagination__1ywOm {
    margin-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .card_list_sort__DgQZw {
    margin-top: 15px;
    width: 100%;
  }

  .card_list_sum__1Nq8W {
    width: 100%;
    justify-content: space-between;
  }
  .card_list_check__3Phxf {
    margin-left: 0px;
  }
  .card_list_cards__3AkXO {
    display: flex;
    justify-content: center;
    margin: 11px auto;
    flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px;
    height: 307px;
    overflow-y: auto;
  }

    .card_list_cardsSmall__3q__V {
      margin-top: 1px !important;
      padding-top: 16px !important;
      height: 307px;
      overflow-y: auto;
    }
      .card_list_cardsSmallNotFound__7agdj {
        min-height: 327px !important;
        border-bottom: 0px !important;
      }
      .card_list_cardsHeightLargeModal__3_gDK {
        min-height: 307px;
      }

      .card_list_cardsHeightSmallModal__37iuu {
        min-height: 307px;
        border-bottom: 1px solid #C7C7C7;
      }
    .card_list_cardsNotFound__3o4Fd {
      min-height: auto;
      justify-content: center;
      padding-top: 0px;
    }

  .card_list_cardListModalContentInput__AlSug {
    font-size: 14px;
  }
}

.checkbox_checkbox__1IMOS {
  display: inline-flex;
  align-items: center;
}

.checkbox_inner__nMVSI {
  display: none;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
}

/*
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #000000;
  cursor: pointer;*/

.checkbox_inner__nMVSI:hover,
  .checkbox_inner__nMVSI:focus {
    border: solid 1px #ff6c2d;
  }

.checkbox_inner__nMVSI:checked {
    outline: none;
    -webkit-appearance: none;
            appearance: none;
  }

/*  border: solid 1px #ff6c2d;
    background-color: #ff6c2d;*/

.checkbox_inner__nMVSI:checked::after {
      content: '';
      display: block;
      width: 4px;
      height: 8px;
      position: absolute;
      top: 3px;
      left: 6px;
      border: 2px solid #ffffff;
      border-top: 0;
      border-left: 0;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
    }
.checkbox_checked__1j9Fy {
  cursor: pointer;
  background: #ff6c2d;
  padding: 2.5px 7px;
  border-radius: 12px;
  color: #ffffff;
  border: 1px solid #ff6c2d;
}
.checkbox_unchecked__1EnNz {
  cursor: pointer;
  background: #fff;
  padding: 2.5px 7px;
  border-radius: 12px;
  color: #000000;
  border: 1px solid #000000;
}
.checkbox_text__2weft {
  font-size: 12px;
  z-index: 1100;
}

.input_input__jhdgh {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  height: 56px;
  border-radius: 8px;
  padding: 4px 8px;
  color: var(--color-light-primary);
  border: 2px solid var(--color-background-tertiary);
  background-color: var(--color-background-tertiary);
}

  .input_input__jhdgh:hover,
  .input_input__jhdgh:focus {
    border: 2px solid var(--color-highlight);
  }

  .input_input__jhdgh::-webkit-outer-spin-button,
  .input_input__jhdgh::-webkit-inner-spin-button {
    -webkit-appearance: none;
            appearance: none;
    margin: 0;
  }

.modal {
  width: 369px;
  border-radius: 8px;
  background-color: #fff;
}

  .modal-inner {
    padding: 24px 24px;
  }

  .modal-header {
    position: relative;
    display: flex;
    align-items: center;
  }

  .modal-title {
    width: 90%;
    font-size: 18px;
    text-align: left;
  }

  .modal-close {
    position: absolute;
    display: inline-flex;
    top: 0;
    right: 0;
  }

  .modal-close svg {
      width: 24px;
      height: 24px;
    }

  .modal-close:hover,
    .modal-close:focus {
    }

.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-mask-bg);
  z-index: var(--z-index-modal);
}

.mask.mask-enter {
    opacity: 0;
  }

.mask.mask-enter .modal {
      opacity: 0;
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }

.mask.mask-enter-active {
    opacity: 1;
    transition: opacity 0.3s;
  }

.mask.mask-enter-active .modal {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
      transition: opacity 0.3s, -webkit-transform 0.3s;
      transition: opacity 0.3s, transform 0.3s;
      transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
    }

.mask.mask-exit {
    opacity: 1;
  }

.mask.mask-exit .modal {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }

.mask.mask-exit-active {
    opacity: 0;
    transition: opacity 0.3s;
  }

.mask.mask-exit-active .modal {
      opacity: 0;
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
      transition: opacity 0.3s, -webkit-transform 0.3s;
      transition: opacity 0.3s, transform 0.3s;
      transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
    }

@media screen and (max-width: 425px) {
  .modal {
    width: 92%;
  }
}

.pagination_pagination__1b6wU {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.pagination_pc__cVugE {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.pagination_mobile__1gaBl {
  display: none;
}
.pagination_nextBtn__JrVzT {
  padding-left: 10px;
  text-align: left;
  background-image: url(/static/media/arrow-right.32f5d866.svg);
  background-position: 90% 50%;
}
.pagination_nextBtnMobile__3ttSR {
    width: 50px !important;
    background-position: center;
  }
.pagination_nextBtnMobile__3ttSR:hover,.pagination_nextBtnMobile__3ttSR:disabled {
       background-position: center !important;
    }
.pagination_nextBtn__JrVzT:hover {
    background-color: #000;
    color: #fff;
    background-image: url(/static/media/arrow-right-hover.4f88f8ef.svg);
    background-position: 88% 50%;
    background-repeat: no-repeat;
  }
.pagination_nextBtn__JrVzT:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url(/static/media/arrow-right-hover.4f88f8ef.svg);
    border: solid 2px #e5e5e5;
  }
.pagination_prevBtn__1k_Fn {
  padding-right: 10px;
  text-align: right;
  background-image: url(/static/media/arrow-left.a39550f9.svg);
  background-position: 10% 50%;
}
.pagination_prevBtnMobile__21tSD {
     width: 50px !important;
     background-position: center;
  }
.pagination_prevBtnMobile__21tSD:hover,.pagination_prevBtnMobile__21tSD:disabled {
        background-position: center !important;
     }
.pagination_prevBtn__1k_Fn:hover {
    background-color: #000;
    color: #fff;
    background-image: url(/static/media/arrow-left-hover.10fd9885.svg);
    background-position: 10% 50%;
    background-repeat: no-repeat;
  }
.pagination_prevBtn__1k_Fn:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url(/static/media/arrow-left-hover.10fd9885.svg);
    border: solid 2px #e5e5e5;
  }
.pagination_button__2xeL_ {
  width: 91px;
  height: 30px;
  font-size: 18px;
  border-radius: 15px;
  color: #000;
  background-color: #fff;
  border: solid 2px #000;
}
.pagination_text__1wDYx {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.pagination_page__2CPhX {
  margin: 0 24px;
  font-size: 18px;
}

.pagination_input__2tRyC {
  margin: 0 12px;
  width: 55px;
  height: 29px;
  text-align: center;
  /* border-radius: 4px; */
  border-bottom: solid 1px #000;
  color: #000;
  background-color: var(--color-background-tertiary);
}

@media screen and (max-width: 768px) {
  .pagination_pc__cVugE {
    display: none;
  }
  .pagination_mobile__1gaBl {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
    .pagination_mobilePageBar__nOP7g {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  .pagination_page__2CPhX {
    text-align: center;
  }
}

.select_select__2ohZ4 {
  --color-highlight: none;
  --color-light-primary: none;
  --color-light-secondary: none;
  --color-background-tertiary: none;
  --color-background-secondary: none;
  --color-background-primary: none;
  color: #000;
  padding: 6px 24px 6px 0;
  border-bottom: solid 1px #000;
  font-size: 14px;
}
  .select_selectWrapper__1PSeM {
    position: relative;
  }
select {
  border: solid 1px #000;
  /*很关键：将默认的select选择框样式清除*/
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*在选择框的最右侧中间显示小箭头图片*/
  background: url('https://raw.githubusercontent.com/ourjs/static/gh-pages/2015/arrow.png')
    no-repeat scroll right center transparent;
  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-right: 14px;
}
select::-ms-expand {
  display: none; /* remove default arrow in IE 10 and 11 */
}

.select_option__38Ulf {
  color: var(--color-highlight);
  background-color: var(--color-background-primary);
}

.select_arrowDown__2ZcJt {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  right: 0;
  height: 16px;
  width: 16px;
  fill: currentColor;
}

.slider_slider__3TXi0 {
  position: relative;
  width: 212px;
  margin-top: 3px;
}
  .slider_sliderSteps__3mN36 {
    position: absolute;
    top: -22px;
    right: 0;
    font-size: 14px;
    color: #ff6c2d;
  }
  .slider_sliderInput__2kkEm {
    position: relative;
    /* -webkit-appearance: none; */
    /* appearance: none; */
    /* border: none; */
    width: 100%;
    height: 2px;
    /* background-color: red; */
    z-index: 2;
    -webkit-appearance: none; /*清除系统默认样式*/
    width: 100% !important;
    border-bottom: 1px solid #000000;
  }
  /*background-size: 30% 100%;!*设置左右宽度比例*!*/
  .slider_sliderInput__2kkEm::-moz-range-thumb {
      /*appearance: none;*/
      /*width: 8px;*/
      /*height: 18px;*/
      /*border: solid 2px var(--color-background-secondary);*/
      /*border-radius: 2px;*/
      /*background-color: var(--color-light-primary);*/
      /*pointer-events: auto;*/
      /*cursor: pointer;*/
      cursor: pointer;
      background: #000;
      -webkit-appearance: none; /*清除系统默认样式*/
      height: 18px; /*拖动块高度*/
      width: 18px; /*拖动块宽度*/
      border-radius: 50%; /*外观设置为圆形*/
      border: solid 1px #000; /*设置边框*/
    }
  .slider_sliderInput__2kkEm::-ms-thumb {
      /*appearance: none;*/
      /*width: 8px;*/
      /*height: 18px;*/
      /*border: solid 2px var(--color-background-secondary);*/
      /*border-radius: 2px;*/
      /*background-color: var(--color-light-primary);*/
      /*pointer-events: auto;*/
      /*cursor: pointer;*/
      cursor: pointer;
      background: #000;
      -webkit-appearance: none; /*清除系统默认样式*/
      height: 18px; /*拖动块高度*/
      width: 18px; /*拖动块宽度*/
      border-radius: 50%; /*外观设置为圆形*/
      border: solid 1px #000; /*设置边框*/
    }
  .slider_sliderInput__2kkEm::-webkit-slider-thumb {
      /*appearance: none;*/
      /*width: 8px;*/
      /*height: 18px;*/
      /*border: solid 2px var(--color-background-secondary);*/
      /*border-radius: 2px;*/
      /*background-color: var(--color-light-primary);*/
      /*pointer-events: auto;*/
      /*cursor: pointer;*/
      cursor: pointer;
      background: #000;
      -webkit-appearance: none; /*清除系统默认样式*/
      height: 18px; /*拖动块高度*/
      width: 18px; /*拖动块宽度*/
      border-radius: 50%; /*外观设置为圆形*/
      border: solid 1px #000; /*设置边框*/
    }
  .slider_slider__3TXi0::before {
    content: '';
    position: absolute;
    top: 12px;
    height: 3px;
    left: 0;
    width: var(--width);
    /*background-color: var(--color-highlight);*/
    pointer-events: none;
    z-index: 1;
  }
  .slider_slider__3TXi0::after {
    content: '';
    position: absolute;
    top: 12px;
    height: 3px;
    left: 0;
    right: 0;
    background-color: var(--color-background-tertiary);
    pointer-events: none;
  }

.slider_steps__1XsKb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
}

.slider_step__1g0c9 {
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .slider_slider__3TXi0 {
    position: relative;
    width: 100%;
  }
    .slider_sliderInput__2kkEm::-moz-range-thumb {
      height: 22px; /*拖动块高度*/
      width: 22px; /*拖动块宽度*/
    }
    .slider_sliderInput__2kkEm::-ms-thumb {
      height: 22px; /*拖动块高度*/
      width: 22px; /*拖动块宽度*/
    }
    .slider_sliderInput__2kkEm::-webkit-slider-thumb {
      height: 22px; /*拖动块高度*/
      width: 22px; /*拖动块宽度*/
    }
}

.table_tableHeaderRow__1CRVs:nth-child(2) {
}
.table_table__2bx3B {
  display: table;
  width: 100%;
  min-width: 448px;
  overflow-x: scroll;
  table-layout: fixed;
  border-collapse: collapse;
}
.table_tableWrapper__3GeVH {
    width: 100%;
    overflow-y: auto;
    max-height: 516px;
    padding: 1px 0px;
  }
.table_tableHeader__1BOvU {
    display: table-header-group;
  }
.table_tableHeaderRow__1CRVs {
      display: table-row;
      height: 36px;
    }
.table_tableHeaderCell__3X_Kj {
      font-size: 12px;
      color: #909090;
      display: table-cell;
      vertical-align: middle;
      background-color: #eeeeee;
      padding: 0px 10px;
    }
.table_tableHeaderCell__3X_Kj:nth-child(2) {
        padding-left: 0px;
      }
.table_tableHeaderCell__3X_Kj:first-child {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
      padding: 0px;
    }
.table_tableHeaderCell__3X_Kj:last-child {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
.table_tableHeaderCell__3X_Kj:nth-last-child(2) {
      padding-right: 0px;
      width: 72px;
    }
.table_tableBody__1jQeE {
    display: table-row-group;
    position: relative;
  }
.table_tableBodyRow__3kRxW {
      position: relative;
      display: table-row;
      height: 48px;
    }
.table_tableBodyRowSplit__rP6ng {
        position: absolute;
        width: 428px;
        border-bottom: 1px solid #000;
        margin-left: 10px;
      }
.table_tableBodyCell__25xH2 {
      font-size: 12px;
      display: table-cell;
      vertical-align: middle;
      padding: 0px 10px;
      width: 71px;
      border-bottom: solid 1px #000;
    }
.table_tableBodyCell__25xH2 a {
        text-decoration: underline;
      }
.table_tableBodyCell__25xH2:nth-child(2) {
        padding-left: 0px;
      }
.table_tableBodyCell__25xH2:first-child {
      border-bottom: 0px;
    }
.table_tableBodyCell__25xH2:last-child {
      border-bottom: 0px;
    }
.table_tableBodyCell__25xH2:nth-last-child(2) {
      padding-right: 0px;
      width: 10%;
    }


.table_table__1xeD3 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
  .table_tableWrapper__2w5Bk {
    width: 100%;
    padding: 1px 0px;
  }
  .table_tableHeader__3S9Lo {
    display: flex;
  }
  .table_tableHeaderRow__1ZsKJ {
       display: flex;
       height: 36px;
       flex: 1 1;
       padding: 0 10px;
       align-items: center;
       background-color: #eeeeee;
       border-radius: 8px;
    }
  .table_tableHeaderCell__3-kiB {
      font-size: 12px;
      font-weight: 600;
      color: #909090;
      display: flex;
      align-items: center;
      vertical-align: middle;
    }
  .table_tableHeaderCell__3-kiB:not(:last-child) {
       padding-right: 10px;
    }
  .table_tableBody__1Kc8R {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .table_tableBodyRow__3b68l {
      position: relative;
      display: flex;
      height: 64px;
      margin: 0 10px;
      border-bottom: 1px solid #EEEEEE;
    }
  .table_tableBodyRowSplit__3LVRr {
        border-bottom: 1px solid #EEEEEE;
        margin-left: 10px;
      }
  .table_tableBodyCell__3hAaE {
      font-size: 12px;
      display: flex;
      align-items: center;
      vertical-align: middle;
      width: 71px;
    }
  /*border-bottom: solid 1px #000;*/
  .table_tableBodyCell__3hAaE a {
        text-decoration: underline;
      }
  .table_tableBodyCell__3hAaE a:hover {
          color: #000000;
        }
  .table_tableBodyCell__3hAaE:not(:last-child) {
      padding-right: 10px;
    }

.wallet_wallet__34pyU {
  height: 40px;
  left: 8px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #000000;
  border: 2px solid #000;
  border-radius: 15px;
  justify-content: center;
  padding: 10px 22px;
}

  .wallet_wallet__34pyU:hover {
    color: #fff !important;
    background-color: #000;
    border: 2px solid #000;
  }

  .wallet_wallet__34pyU:focus {
    color: #fff;
    background-color: #000;
    border: 2px solid #fff;
  }

  .wallet_walletTop__1fOvP {
    width: 100%;
    justify-content: space-between;
    margin: 15px 0;
    flex-direction: column;
  }

  .wallet_walletMenu__1rw8G {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    /*padding: 110px 0;*/
    /*border-bottom: 0.5px solid #909090;*/
  }

  .wallet_walletInfo__7aWHR {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    margin-right: 0;
    margin-top: 146px;
  }

  .wallet_walletConnect__14_8A {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 20px;
    font-weight: 600;
  }

  .wallet_walletFlex__gLSIn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

.wallet_walletDisabled__2bgu3 {
  height: 40px;
  left: 8px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #909090;
  border: 2px solid #909090;
  border-radius: 15px;
  justify-content: center;
  padding: 10px 22px;
}

.wallet_selectOption__2FM9W {
    display: flex;
    align-items: center;
  }

.wallet_selectOption__2FM9W svg {
      width: 16px !important;
      height: 16px !important;
      margin-right: 4px;
    }

.wallet_mobile__22Cq2 {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.wallet_mobileMenu__2fFzC {
     display: flex;
     width: 100%;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     font-size: 20px;
     font-weight: 400;
     padding-top: 20px;
     padding-bottom: 56px;
     border-bottom: 0.5px solid #909090;
     margin-bottom: 56px;
  }

.wallet_mobileMenuItem__1Fvh8:not(:last-child) {
       margin-bottom: 48px;
     }

.wallet_mobileMenuItem__1Fvh8 {
      display: inline-block;
      position: relative;
      font-size: 20px;
      line-height: 22px;
      text-align: center;
      color: #000000;
    }

.wallet_mobileMenuItemActive__2KgU7::before {
          width: 12px;
          height: 12px;
          background: #ff6c2d;
          border-radius: 15px;
          content: '';
          position: absolute;
          left: -17px;
          top: 10px;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }

.wallet_mobileMenuItemActive__2KgU7::after {
          width: 100%;
          background: none;
        }

.wallet_mobileMenuItem__1Fvh8 a:visited {
        color: #000000;
      }

.wallet_mobileMenuItemTip__1K79X {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -6px;
        right: -28px;
        width: 35px;
        height: 14px;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 12px;
        line-height: 10px;
        background: #83D300;
        border-radius: 10px;
        padding: 2px 4px;
      }

.wallet_mobileMenuItemTip__1K79X span {
          display: inline-block;
          -webkit-transform: scale(0.75);
                  transform: scale(0.75);
        }

@media (max-width: 850px) {
    .wallet_walletInfo__7aWHR, .wallet_walletDisabledInfo__2WTH5 {
      margin-top: 0px;
    }

}




.select_input_searchInput__9nttv {
  --color-highlight: none;
  --color-light-primary: none;
  --color-light-secondary: none;
  --color-background-tertiary: none;
  --color-background-secondary: none;
  --color-background-primary: none;
  color: #000;
  padding: 6px 24px 6px 0;
  border-bottom: solid 1px #000;
  font-size: 14px;
}
  .select_input_searchInputWrapper__2UWVs {
     position: relative;
     border-bottom: 1px solid #000000;
     display: flex;
     height: 24px;
     justify-content: center;
     align-items: center;
     padding-bottom: 5px;
  }
  .select_input_searchInputWrapper__2UWVs svg {
      fill: none !important;
      width: 14px !important;
      height: 14px !important;
      position: absolute;
      left: 0px;
    }
  .select_input_searchInputWrapper__2UWVs .select_input_input__3PEvI {
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      color: #909090;
      padding-left: 20px;
    }
  .select_input_searchInputWrapper__2UWVs .select_input_input__3PEvI::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 16px;
      width: 16px;
      background: url(https://yxs-web.oss-cn-beijing.aliyuncs.com/328e4d97f9d0d68ea04e872f68e508e3.png) no-repeat;
      background-size: contain;
    }
  .select_input_searchInputWrapper__2UWVs .select_input_close__2a99H {
      cursor: pointer;
    }


.account_account__2EV2D {
  position: relative;
  /*height: 100%;*/
  padding: 0 24px;
}

.account_event__3g5U8 {
  font-size: 12px;
  font-weight: 600;
}

.account_inner__1hRHc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.account_account__2EV2D .account_total__3nsBC .account_caption__3ir6B {
  margin-left: 0px;
  margin-right: 0px;
}

.account_caption__3ir6B {
  margin-top: 24px;
  margin-bottom: 12px;
  font-family: 'Archivo';
  font-size: 24px;
  /*margin: 8px 0px;*/
  font-size: 18px;
  border-bottom: 0.5px solid #909090;
  font-weight: normal;
}

.account_captionInventory__288jg {
    margin-top: 24px;
    margin-bottom: 12px;
    font-family: 'Archivo';
    font-size: 24px;
    /*margin: 8px 0px;*/
    font-size: 18px;
    border-bottom: 0.5px solid #909090;
    font-weight: normal;
  }

.account_balance__dR6ps {
  /*position: relative;*/
  /*margin: 16px 0;*/
  /*padding: 16px 32px;*/
  /*max-width: 472px;*/
}

/*background-color: var(--color-background-tertiary);*/

.account_balanceIcon__-pTZa {
    position: absolute;
    top: 16px;
    right: 32px;
    width: 72px;
    height: 72px;
    fill: none;
  }

/*border-radius: 4px;*/

.account_balanceETH__dUTRp {
    font-size: 32px;
  }

/*width: 100%;*/

.account_balanceUSD__1yG0S {
    align-items: center;
    font-size: 12px;
    color: #ff6c2d;
    border: 1px solid #ff6c2d;
    border-radius: 12px;
    height: 13px;
    padding: 2px 5px;
  }

.account_address__iPPga {
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  overflow-wrap: anywhere;
  margin-top: 20px;
}

.account_address__iPPga a {
    text-decoration: underline;
  }

.account_address__iPPga a:hover,
  .account_address__iPPga a:visited {
    color: #000000 !important;
  }

.account_address__iPPga svg {
    width: 24px;
    height: 24px;
    fill: none;
  }

.account_copy__3VM-S {
  margin-left: 8px;
  flex: none;
}

.account_total__3nsBC {
  min-width: 180px;
  max-width: 291px;
  /*display: flex;*/
  /*flex-wrap: wrap;*/
}

.account_inventory__2E3eN {
  max-width: 940px;
}

.account_inventoryList__3rFZS {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 24px;
    grid-gap: 24px;
    gap: 24px;
  }

.account_pagination__1aIDF {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.account_cursorInherit__1ZcOE {
  cursor: inherit;
}

.account_right__2Hoho {
  width: 100%;
  margin-left: 102px;
}

.account_rightView__1x9ZH {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.account_activity__2c0xn {
  width: 100%;
  min-width: 448px;
}

.account_activityTableRowSplit__1lc50 {
  width: 97.5%;
}

.account_openOutside__1VP_O svg {
  fill: none;
}

.account_noResult__1Rf6n {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
  background: rgba(238, 238, 238, 0.5);
}

.account_noResultInfo__3H_-e {
    font-size: 14px;
    line-height: 16px;
    color: #909090;
  }

.account_noResult__1Rf6n div {
    text-align: center;
  }

.account_noResult__1Rf6n svg {
    width: 60px;
    height: 60px;
  }

.account_batchTransfer__2csHF {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.account_batchTransferTitle__3V2of {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
    padding-top: 48px;
    padding-bottom: 8px;
    border-bottom: 0.5px solid #909090;
  }

.account_batchTransferInfo__3wN0E {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-transform: uppercase;
    color: #909090;
    margin-bottom: 24px;
  }

.account_batchTransfer__2csHF svg {
    width: 24px;
    height: 24px;
    fill: none;
    margin-top: 25px;
    margin-bottom: 13px;
  }

.account_cardListModalNormal__815np {
    width: 975px;
    height: 655px;
  }

.account_cardListModalMiddle__3UOES {
    width: 504px;
    height: 655px;
  }

.account_cardListModalBottom__1xiak {
    display: flex;
    align-items: center;
    justify-content: center;
  }

.account_cardListModalBtnBack__17Dx8 {
      margin-right: 20px;
    }

.account_cardListModalBtnBackSmall__2FzIs {
        width: 186px !important;
      }

.account_cardListModalTransferBtn__2Ht64 {
      height: 40px;
      line-height: 40px;
      width: 212px;
      padding: 0px;
      text-align: center;
      border-radius: 15px;
      margin-bottom: 10px;
      font-size: 18px;
      border: 2px solid #ff6c2d;
      color: #fff !important;
      background-color: #ff6c2d !important;;
      margin-top: 10px;
    }

.account_cardListModalTransferBtnDisable__2tD95 {
        height: 40px;
        line-height: 40px;
        width: 212px;
        padding: 0px;
        text-align: center;
        border-radius: 15px;
        margin-bottom: 10px;
        font-size: 18px;
        margin-top: 10px;
        color: #909090 !important;
        background: #FFFFFF !important;;
        border: 2px solid #909090;
      }

.account_cardListModalTransferError__2aAcD {
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      color: #FF0000;
      min-height: 18px;
      margin-top: 5px;
    }

.account_cardListModalContent__vXZA1 {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

.account_cardListModalContentConfirm__3mghC {
      margin: 20px 0px;
    }

.account_cardListModalContentConfirm__3mghC span {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #909090;
        margin-bottom: 8px;
      }

.account_cardListModalContentConfirm__3mghC div {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        -webkit-text-decoration-line: underline;
                text-decoration-line: underline;
        color: #000000;
      }

.account_cardListModalContentConfirmLarge__1UAqt {
        display: flex;
        margin: 10px 0px;
      }

.account_cardListModalContentConfirmLarge__1UAqt span {
          font-weight: 400;
          font-size: 18px;
          line-height: 20px;
          color: #909090;
          margin-right: 12px;
        }

.account_cardListModalContentConfirmLarge__1UAqt div {
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          -webkit-text-decoration-line: underline;
                  text-decoration-line: underline;
          color: #000000;
        }

.account_cardListModalContentPagination__LGTxg {
      margin-top: 36px;
    }

.account_cardListModalContentPaginationLarge__3eQB9 {
        margin-top: 4px;
        padding-top: 0px;
        padding-bottom: 16px;
      }

.account_cardListModalContentInputView__1VCSK {
      position: relative;
    }

.account_cardListModalContentInputViewLarge__Jl69B svg {
          top: 30px !important;
          right: 23px !important;
        }

.account_cardListModalContentInputView__1VCSK svg {
        width: 14px !important;
        height: 14px !important;
        fill: none !important;
        position: absolute;
        right: 0px;
        top: 6px;
      }

.account_cardListModalContentInput__3cZpv {
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      color: #909090;
      width: 100%;
      height: 30px;
      border-radius: 0px;
      padding-right: 15px !important;
      padding-left: 4px !important;
      border-bottom: 1px solid #000000;
    }

.account_cardListModalContentInputLarge__23YjO {
        width: 448px;
        margin-right: 24px;
      }

.account_cardListModalContentInput__3cZpv:hover, .account_cardListModalContentInput__3cZpv:active, .account_cardListModalContentInput__3cZpv:focus {
        border: 0px;
        border-bottom: 1px solid #000000;
      }

.account_cardListModalContentBtn__2jHdg {
      text-align: center;
      border-top: 1px solid #EEEEEE;
    }

.account_cardListModalContentBtnTitle__33CoC {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        margin-top: 13px;
        text-transform: uppercase;
      }

.account_cardListModalContentBtnTitle__33CoC svg {
          width: 24px;
          height: 24px;
          margin-right: 14px;
        }


.account_steps__3wlnT {
  display: block;
}

.account_step__1n-dd {
  margin-top: 24px;
}

.account_step__1n-dd svg {
    width: 16px !important;
    height: 16px !important;
    fill: none;
  }

.account_stepTitle__1hM24 {
    display: flex;
    align-items: center;
    font-size: 18px;
  }

.account_stepTitleText__V0yQ0 {
      margin-left: 5px;
      font-weight: 600;
    }

.account_stepContent__2_3T_ {
    padding: 5px 0px;
    color: #909090;
  }

.account_stepSuccess__3kc3w {
    padding: 5px;
    font-size: 14px;
    color: #ff6c2d;
  }

.account_modalNotice__3r-M7 {
  margin-top: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid #C7C7C7;
}

.account_modalNoticeTitle__30I1y {
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #909090;
    margin-bottom: 8px;
  }

.account_modalNoticeMsg__1GMIC {
    font-size: 12px;
    line-height: 13px;
    color: #909090;
  }

@media (max-width: 850px) {
  .account_right__2Hoho {
    margin-left: 0px;
  }

  .account_account__2EV2D {
    padding: 0px;
  }

    .account_accountInfo__35k6Y {
      display: flex;
      font-size: 12px;
      margin-right: 33px;
      position: relative;
      flex-direction: column;
      align-items: center;
    }
    .account_captionInventory__288jg {
      margin-left: 0px;
      margin-right: 0px;
    }

  .account_address__iPPga {
    position: relative;
  }

  .account_inner__1hRHc {
    flex-direction: column;
    padding: 0 16px;
  }

  .account_total__3nsBC {
    max-width: none;
  }

  .account_inventory__2E3eN {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    margin: 0;
    padding: 0px;
  }

    .account_inventoryList__3rFZS {
      grid-gap: 16px;
      grid-gap: 16px;
      gap: 16px;
      justify-content: center;
    }

  .account_activity__2c0xn {
    margin-bottom: 50px;
    max-width: none;
    min-width: 100%;
    margin-bottom: 50px;
    padding: 0px;
  }

  .account_transfer__3UBFB {
    flex-direction: column;
    padding: 16px 16px;
  }
      .account_transferInfoBlock__UGT6A {
        margin-left: 0px;
        margin-bottom: 20px;
      }

      .account_transferInfoIcon__14HHa {
        display: none;
      }

    .account_transferInner__3pScI {
      flex-direction: column;
      align-items: flex-start;
    }

  .account_activityTableRowSplit__1lc50 {
    width: 95.5%;
  }
}

@media (max-width: 720px) {
    .account_cardListModalNormal__815np {
      width: 95% !important;
      height: 665px;
    }

    .account_cardListModalMiddle__3UOES {
      width: 95% !important;
      height: 665px;
    }

    .account_cardListModalBottom__1xiak {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .account_cardListModalContent__vXZA1 {
      margin: 16px 0px;
    }

      .account_cardListModalContentConfirm__3mghC {
        flex-direction: column;
      }

        .account_cardListModalContentConfirm__3mghC span {
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
        }

        .account_cardListModalContentConfirm__3mghC div {
          margin-top: 8px;
          font-weight: 500;
          font-size: 14px;
          line-height: 15px;
        }

      .account_cardListModalContentInputView__1VCSK {
        width: 100%;
      }

        .account_cardListModalContentInputView__1VCSK svg {
          right: 0px !important;
        }

      .account_cardListModalContentInput__3cZpv {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
      }
        .account_cardListModalContentInput__3cZpv:focus,.account_cardListModalContentInput__3cZpv:active {
          user-select: text;
          -webkit-user-select: text;
          zoom: 1;
        }
        .account_cardListModalContentInputLarge__23YjO {
          width: 100%;
        }
}

@media screen and (max-width: 425px) {
    .account_batchTransfer__2csHF button {
      width: 100%;
    }
}

.header_header__2s1Aq {
  height: 70px;
  background-image: url(/static/media/header-nav.5d20c493.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 35% 0;
}
  /*border-bottom: solid 1px var(--color-light-secondary);*/
  .header_headerCursor__3Ymw2 {
    width: 12px;
    height: 12px;
    background: #ff6c2d;
    border-radius: 10px;
    margin-right: 4px;
  }
.header_header__2s1Aq a:hover,.header_header__2s1Aq a:visited,.header_header__2s1Aq a:active {
  color: #000000;
}
.header_header__2s1Aq  a:hover::after {
  width: 100%;
}
.header_inner__1fJNH {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0 24px;
}
.header_innerAccount__19Q2C {
    display: none;
  }
.header_innerView__2t_JW {
    display: flex;
    align-items: center;
    justify-content: center;
  }
.header_split__3cm35 {
  margin: 0 24px;
  border-bottom: 0.5px solid #000;
}
.header_logo__x4wxZ {
  display: inline-flex;
  margin-right: 89px;
}
.header_logo__x4wxZ svg {
    width: 63px;
    height: 100%;
    margin: 15px 20px;
    margin-left: 0px;
    fill: none;
  }
.header_nav__IqH6V {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.header_navMenu__z7_KU {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
.header_navItem__24nDX::after {
     content: '';
     left: 50%;
     position: absolute;
     height: 1px;
     bottom: 0;
     transition: 0.2s;
     width: 0%;
     -webkit-transform: translateX(-50%);
     transform: translateX(-50%);
     background: linear-gradient(to right, #FF6C2D, #FF6C2D);
  }
.header_navItem__24nDX {
    position: relative;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 18px;
    line-height: 20px;
  }
.header_navItemActive__1Ak-x::before {
         width: 12px;
         height: 12px;
         background: #ff6c2d;
         border-radius: 15px;
         content: '';
         position: absolute;
         left: -17px;
         top: 10px;
         -webkit-transform: translateY(-50%);
         transform: translateY(-50%);
      }
.header_navItemActive__1Ak-x::after {
         width: 100%;
         background: none;
      }
.header_navItemTip__1tum3 {
       position: absolute;
       display: flex;
       align-items: center;
       justify-content: center;
       top: -6px;
       right: -28px;
       width: 35px;
       height: 14px;
       color: #FFFFFF;
       font-weight: 500;
       font-size: 12px;
       line-height: 10px;
       background: #83D300;
       border-radius: 10px;
       padding: 2px 4px;
    }
.header_navItemTip__1tum3 span {
         display: inline-block;
         -webkit-transform: scale(0.75);
                 transform: scale(0.75);
       }
.header_navItem__24nDX:not(:last-child) {
    margin-right: 52px;
  }
.header_navLink__CriGU {
    display: flex;
    font-size: 18px;
    font-weight: 600;
  }
.header_navLink__CriGU.header_active__ek461 {
      font-weight: bold;
      color: #000000;
      border-bottom: 2px solid var(--color-highlight);
    }
.header_navInfo__334A0 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

.header_menu__DxgDf {
  display: none;
}

@media (max-width: 1127px) {
  .header_logo__x4wxZ {
    margin-right: 30px;
  }
}
@media (max-width: 1070px) {
  .header_logo__x4wxZ {
    margin-right: 0px;
  }
  .header_navItem__24nDX:not(:last-child) {
    margin-right: 26px;
  }
}

@media (max-width: 850px) {
  .header_header__2s1Aq {
    height: 56px;
  }
  .header_split__3cm35 {
    margin: 0 16px;
  }
  .header_wallet__1s-kv {
    height: 30px;
    left: 8px;
    font-family: 'Archivo';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #000000;
    border: 2px solid #000;
    border-radius: 15px;
    justify-content: center;
    padding: 10px 22px;
  }
  .header_walletDisabled__2BYde {
    height: 30px;
    left: 8px;
    font-family: 'Archivo';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #909090;
    border: 2px solid #909090;
    border-radius: 15px;
    justify-content: center;
    padding: 10px 22px;
  }
  .header_inner__1fJNH {
    padding: 0 16px;
    height: 56px;
  }
    .header_innerAccount__19Q2C {
      display: flex;
      position: absolute;
      justify-content: center;
      width: 230px;
      left: 50%;
      margin-left: -115px;
      align-items: center;
    }
        .header_innerAccountLinkText__g5eLE {
           height: 30px;
           font-family: 'Archivo';
           font-style: normal;
           font-weight: normal;
           line-height: 20px;
           display: flex;
           align-items: center;
           text-transform: uppercase;
           color: #000000;
           border: 2px solid #000;
           border-radius: 15px;
           justify-content: center;
           padding: 0px 22px;
           font-size: 12px;
           width: 155px;
        }

  .header_logo__x4wxZ {
    font-size: 24px;
  }
    .header_logo__x4wxZ svg {
      width: 52px;
    }

  .header_nav__IqH6V {
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    overflow-y: scroll;
    z-index: var(--z-index-header);
    background-color: #fff;
  }
    .header_nav__IqH6V.header_open___icDe {
      z-index: 1999;
      opacity: 1;
      pointer-events: auto;
      transition: all 0.3s ease-in-out;
    }

    .header_navMenu__z7_KU {
      display: none;
    }

    .header_navItem__24nDX {
      font-size: 18px;
      height: auto;
      width: 100%;
      margin: 16px 0;
    }

    .header_navLink__CriGU {
      justify-content: flex-start;
    }

    .header_navInfo__334A0 {
      margin: 15px 16px;
    }
  .header_menu__DxgDf {
    display: flex;
    width: 22px;
    -webkit-user-select: none;
            user-select: none;
    z-index: var(--z-index-header);
  }

    .header_menuIcon__15tso {
      position: relative;
      width: 22px;
      height: 2px;
      margin: 14px auto;
      background-color: #000;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;
      -webkit-transform-origin: center;
              transform-origin: center;
    }

      .header_menuIcon__15tso::before,
      .header_menuIcon__15tso::after {
        content: '';
        width: 22px;
        height: 2px;
        position: absolute;
        background-color: #000;
        transition: inherit;
        -webkit-transform-origin: center;
                transform-origin: center;
      }

      .header_menuIcon__15tso::before {
        top: -8px;
        left: 0;
      }

      .header_menuIcon__15tso::after {
        top: 8px;
        left: 0;
      }

      .header_menuIcon__15tso.header_open___icDe {
        background-color: transparent;
      }

        .header_menuIcon__15tso.header_open___icDe::before {
          top: 0;
          -webkit-transform: rotate(-45deg);
                  transform: rotate(-45deg);
        }

        .header_menuIcon__15tso.header_open___icDe::after {
          top: 0;
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
        }
}


.account_accountInfo__3j_Hy a:hover {
  color: #ff6c2d !important;
}
.account_selectOption__2grGo {
    display: flex;
    align-items: center;
  }
.account_selectOption__2grGo svg {
      width: 16px !important;
      height: 16px !important;
      margin-right: 4px;
    }
.account_pc__1B9K2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.account_pcETH__2vZYK {
    display: flex;
    align-items: center;
    height: 70px;
    margin-right: 20px;
  }
.account_pcETH__2vZYK ul {
      display: flex !important;
      flex-direction: column;
      margin-left: 100px;
      list-style: none;
      width: 150px;
      height: 24px;
      line-height: 23px;
      border-bottom: 1px solid #000000;
      overflow: hidden;
      cursor: pointer;
      background-color: red;
      background: url('https://raw.githubusercontent.com/ourjs/static/gh-pages/2015/arrow.png')
        no-repeat scroll right center transparent;
    }
.account_pcETH__2vZYK ul li {
        display: flex;
        align-items: center;
      }
/*在选择框的最右侧中间显示小箭头图片*/
.account_pcETH__2vZYK ul li svg {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
.account_pcETH__2vZYK svg {
      width: 102px;
      height: 26px;
    }
.account_mobile__1RDy8 {
  display: none;
}
.account_address__3hwfs svg {
  position: relative;
  width: 12px;
  height: 12px;
  fill: none;
}
.account_account__2DH1h {
  display: flex;
  align-items: center;
  height: 100%;
}
.account_accountBalance__1-X5E {
    float: left;
    position: relative;
    height: 14px;
    line-height: 14px;
  }
.account_accountAddress__1WRqP {
    font-size: 12px;
    cursor: pointer;
  }
.account_accountInfo__3j_Hy {
    display: block;
    font-size: 12px;
    margin-right: 33px;
    position: relative;
  }
.account_accountInfoAddress__3aSlZ {
      height: 12px;
      line-height: 12px;
      float: left;
      clear: both;
    }
.account_accountBalance__1-X5E svg {
    position: absolute;
    top: 0;
    right: -8px;
    width: 6px;
    height: 6px;
  }
.account_accountText__1q7Tp {
    height: 40px;
    font-family: 'Archivo';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #000000;
    border: 2px solid #000;
    border-radius: 15px;
    justify-content: center;
    padding: 0px 22px;
  }
.account_accountText__1q7Tp:hover {
      color: #fff !important;
      background-color: #000;
      border: 2px solid #000;
    }
.account_accountText__1q7Tp svg {
      width: 32px;
      height: 32px;
      fill: none;
    }
.account_accountActive__B77iT:visited {
      color: #000;
    }

.account_balance__2wkKB {
  font-size: 14px;
  font-weight: 600;
}
.account_address__3hwfs {
  position: absolute;
  right: -12px;
  top: 12px;
  font-size: 12px;
  cursor: pointer;
}

@media (max-width: 1070px) {
  .account_pcETH__2vZYK {
    display: none;
  }
}
@media (max-width: 850px) {
  .account_pc__1B9K2 {
    display: none;
  }
  .account_address__3hwfs {
    position: relative;
    right: 0;
    top: 0;
  }
  .account_accountText__1q7Tp {
    font-size: 12px;
    margin-top: 28px;
    width: 166px;
  }
  .account_account__2DH1h {
    width: 100%;
    justify-content: space-between;
    margin: 15px 0px;
    flex-direction: column;
  }
    .account_accountInfo__3j_Hy {
      display: flex;
      position: relative;
      align-items: center;
      flex-direction: column;
      margin-right: 0px;
      margin-top: 20px;
    }
    .account_accountLink__31dct {
       margin-bottom: 50px;
    }
    .account_accountView__29PpT {
      display: flex;
    }
  .account_mobile__1RDy8 {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
    .account_mobileMenu__iWEZB {
       display: flex;
       width: 100%;
       align-items: center;
       justify-content: center;
       flex-direction: column;
       font-size: 20px;
       font-weight: 400;
       padding-top: 20px;
       padding-bottom: 56px;
       border-bottom: 0.5px solid #909090;
       margin-bottom: 36px;
    }
      .account_mobileMenuItem__3W2fG:not(:last-child) {
         margin-bottom: 48px;
      }
      .account_mobileMenuItem__3W2fG {
         position: relative;
         font-size: 20px;
         line-height: 22px;
         text-align: center;
         color: #000000;
      }
           .account_mobileMenuItemActive__19Wt9::before {
              width: 12px;
              height: 12px;
              background: #ff6c2d;
              border-radius: 15px;
              content: '';
              position: absolute;
              left: -17px;
              top: 10px;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
            }
           .account_mobileMenuItemActive__19Wt9::after {
              width: 100%;
              background: none;
           }
         .account_mobileMenuItemTip__3Eoam {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -6px;
            right: -28px;
            width: 35px;
            height: 14px;
            color: #FFFFFF;
            font-weight: 500;
            font-size: 12px;
            line-height: 10px;
            background: #83D300;
            border-radius: 10px;
            padding: 2px 4px;
         }
            .account_mobileMenuItemTip__3Eoam span {
              display: inline-block;
              -webkit-transform: scale(0.75);
                      transform: scale(0.75);
            }
}
@media (max-width: 960px) {
    .account_pcETH__2vZYK {
      display: none;
    }
}
@media (max-width: 320px) {
  .account_pc__1B9K2 {
    display: none;
  }
  .account_accountText__1q7Tp {
    font-size: 12px;
    margin-top: 28px;
  }
  .account_mobile__1RDy8 {
    display: flex;
    flex-direction: column;
  }
}

.footer_footer__Bm2VQ {
    background: #000000;
    color: #FFFFFF;
    margin-top: 90px;
}
.footer_footer__Bm2VQ a {
    color: #FFFFFF;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}
.footer_footer__Bm2VQ a:hover,.footer_footer__Bm2VQ a:visited,footer a:active {
    color: #FFFFFF;
}
.footer_footer__Bm2VQ .footer_logo__29EO7 {
    margin-bottom: 77px;
    display: flex;
}
.footer_footer__Bm2VQ .footer_logo__29EO7 svg {
    width: 64px;
    height: 33px;
}
.footer_footer__Bm2VQ .footer_footerColumnTitle__1wviq {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 8px;
}
.footer_footer__Bm2VQ .footer_footerColumn__3qJeT:not(:last-child) {
    padding-right: 10px;
    padding-bottom: 25px;
    margin-bottom: 98px;
}
.footer_footer__Bm2VQ a {
    color: #FFFFFF;
}
.footer_footer__Bm2VQ .footer_container__2Z6sS {
    display: flex;
    max-width: 1440px;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
    padding:0px 103px;
    padding-top: 64px;
    padding-bottom: 128px;
}
.footer_footer__Bm2VQ .footer_footerBottom__te4OK {
    display: flex;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0px 103px;
    padding-bottom: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer_footer__Bm2VQ .footer_footerBottom__te4OK .footer_footerBottomRight__gu_uj {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer_footer__Bm2VQ .footer_footerBottom__te4OK .footer_info__2PkER {
    font-size: 12px;
    line-height: 14px;
}
.footer_footer__Bm2VQ .footer_footerBottom__te4OK .footer_info__2PkER:not(:last-child) {
    margin-right: 48px;
}
.footer_footer__Bm2VQ .footer_footerBottom__te4OK .footer_info__2PkER a {
    background: none !important;
    color: #FFFFFF !important;
    padding: 0px !important;
    font-weight: normal !important;
    font-family: Archivo !important;
}
.footer_footer__Bm2VQ .footer_footerColumn__3qJeT .footer_socials__1SXbm {
    display: flex;
    justify-content: flex-end;
}
.footer_footer__Bm2VQ .footer_footerColumn__3qJeT .footer_socials__1SXbm svg {
    color: #FFFFFF;
    width: 28px;
    height: 28px;
}
.footer_footer__Bm2VQ .footer_footerColumn__3qJeT .footer_socials__1SXbm a {
    cursor: pointer;
    display: inline-block;
}
.footer_footer__Bm2VQ .footer_footerColumn__3qJeT .footer_socials__1SXbm a:not(:last-child) {
    margin-right: 18px;
}
.footer_footer__Bm2VQ .footer_footerColumn__3qJeT .footer_footerColumn-title__3ec2j {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 8px;
}
.footer_footer__Bm2VQ .footer_footerColumn__3qJeT .footer_info__2PkER {
    font-size: 12px;
    line-height: 14px;
    margin-top: 4px;
    font-family: Archivo !important;
    font-weight: normal !important;
}

.footer_footer__Bm2VQ .footer_footerColumn__3qJeT .footer_footerColumnSubtitle__3hVaI,.footer_footer__Bm2VQ .footer_footerColumn__3qJeT .footer_footerColumnInfo__lYSM0{
    margin-top: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}
.footer_footer__Bm2VQ .footer_footerColumn__3qJeT .footer_footerColumnInfo__lYSM0 {
    margin-top: 2px;
    font-weight: normal;
}
.footer_footer__Bm2VQ svg path{
    fill: #FFFFFF;
}
.footer_footer__Bm2VQ .footer_footerColumn__3qJeT:nth-child(1) {
    width: 25%;
}
.footer_footer__Bm2VQ .footer_footerColumn__3qJeT:nth-child(2) {
    width: 18%;
    min-width: 120px;
}
.footer_footer__Bm2VQ .footer_footerColumn__3qJeT:nth-child(3) {
    width: 17%;
    min-width: 145px;
}
.footer_footer__Bm2VQ .footer_footerColumn__3qJeT:nth-child(4) {
    text-align: right
}

@media (max-width: 960px) {
    .footer_footer__Bm2VQ .footer_footerColumn__3qJeT .footer_socials__1SXbm {
        justify-content: flex-start;
    }
    .footer_footer__Bm2VQ .footer_footerColumn__3qJeT:not(:last-child) {
        padding-bottom: 0px;
    }
}

@media (max-width: 720px) {

    .footer_footer__Bm2VQ .footer_container__2Z6sS {
        padding: 0px 103px;
        padding-top: 64px;
        padding-bottom: 132px;
    }
    .footer_footer__Bm2VQ .footer_footerColumn__3qJeT:not(:last-child) {
        padding-bottom: 0px;
    }
    .footer_footer__Bm2VQ .footer_footerBottom__te4OK {
        align-items: start;
        padding: 0px 103px;
        padding-bottom: 72px;
        flex-direction: column;
        justify-content: space-between;
    }
    .footer_footer__Bm2VQ .footer_footerBottom__te4OK .footer_footerBottomLeft__1ql76 {
        margin-bottom: 64px;
    }

    .footer_footer__Bm2VQ .footer_footerColumn__3qJeT:first-child {
        width: 100% !important;
        margin-bottom: 0px;

    }

    .footer_footer__Bm2VQ .footer_footerColumn__3qJeT:nth-child(2) {
        width: 48%;
    }

    .footer_footer__Bm2VQ .footer_footerColumn__3qJeT:nth-child(3) {
        width: 52% !important;
    }

    .footer_footer__Bm2VQ .footer_logo__29EO7 {
        margin-bottom: 48px !important;
    }
}
@media (max-width: 490px) {
    .footer_footer__Bm2VQ .footer_container__2Z6sS,.footer_footer__Bm2VQ .footer_footerBottom__te4OK  {
        padding: 0px 32px;
        padding-top: 64px;
        padding-bottom: 68px;
    }
}
@media (max-width: 390px) {
    .footer_footer__Bm2VQ .footer_footerColumn__3qJeT:nth-child(1),.footer_footer__Bm2VQ .footer_footerColumn__3qJeT:nth-child(2),.footer_footer__Bm2VQ .footer_footerColumn__3qJeT:nth-child(3),.footer_footer__Bm2VQ .footer_footerColumn__3qJeT:nth-child(4)  {
        width: 100% !important;
        min-width: auto;
    }
    .footer_footerColumn__3qJeT:nth-child(3) {
        margin-top: 48px;
    }
    .footer_footer__Bm2VQ .footer_footerColumn__3qJeT:nth-child(2){
        margin-bottom: 0px;
    }
    .footer_footer__Bm2VQ .footer_footerColumn__3qJeT:nth-child(3){
        margin-top: 48px;
        margin-bottom: 100px;
    }
    .footer_footer__Bm2VQ .footer_container__2Z6sS {
        padding: 0px 32px;
        padding-top: 64px;
        padding-bottom: 69px;
    }
    .footer_footer__Bm2VQ .footer_footerBottom__te4OK {
        align-items: start;
        padding: 0px 32px;
        padding-bottom: 72px;
        flex-direction: column;
        justify-content: space-between;
    }
    .footer_footer__Bm2VQ .footer_footerBottom__te4OK .footer_footerBottomLeft__1ql76 {
        margin-bottom: 64px;
    }
}

.base_base__1AZWc {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.base_transfer__2-nQm svg {
  width: 24px;
  height: 24px;
  fill: none;
}
.base_transfer__2-nQm {
  padding: 15px 24px;
  margin: 0 auto;
}
.base_transferMigrate__2O5p7 {
    background-color: #83d300 !important;
  }
.base_transferMigrateBtn__385dX {
      height: 30px;
      line-height: 30px;
      font-size: 18px;
      border: 2px solid #000000;
      text-align: center;
      border-radius: 15px;
      padding: 0px 10px !important;
      width: auto !important;
      margin-bottom: 0px !important;
      background-color: #83d300 !important;
      color: #000000 !important;
    }
.base_transferInner__15Abq {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #eeeeee;
    border-radius: 11px;
    padding: 20px 20px;
  }
.base_transferMenu__3FupF {
    min-width: 345px;
    text-align: right;
  }
.base_transferBtn__3sKtL {
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    padding: 0px 10px !important;
    width: auto !important;
    margin-bottom: 0px !important;
    background-color: #eeeeee !important;
    color: #ff6c2d !important;
    border: 1px solid #ff6c2d;
  }
.base_transferInfo__2NDe1 {
    display: flex;
    align-items: center;
  }
.base_transferInfoIcon__1j7SL {
      display: flex;
      align-items: center;
    }
.base_transferInfoBlock__3xl62 {
      text-align: left;
      margin-left: 15px;
    }
.base_transferInfoBlockTitle__3hB4N {
        font-weight: 600;
        font-size: 18px;
      }
.base_transferInfoBlockMsg__2-AqD {
        font-size: 14px;
        line-height: 16px;
      }

.base_steps__3NkbC {
  display: block;
}

.base_step__3OeBR {
  margin-top: 24px;
}

.base_step__3OeBR svg {
    fill: none;
    width: 14px;
    height: 14px;
  }

.base_stepTitle__1kacP {
    display: flex;
    align-items: center;
    font-size: 18px;
  }

.base_stepTitleText__1R1c0 {
      margin-left: 5px;
      font-weight: 600;
    }

.base_stepContent__1efQ1 {
    padding: 5px;
    color: #909090;
  }

.base_stepSuccess__2aLeg {
    padding: 5px;
    font-size: 14px;
    color: #ff6c2d;
  }

.base_modalNotice__3ftav {
  margin-top: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid #C7C7C7;
}

.base_modalNoticeTitle__2NXyp {
     font-weight: 600;
     font-size: 14px;
     line-height: 15px;
     color: #909090;
     margin-bottom: 8px;
  }

.base_modalNoticeMsg__tlSUs {
     font-size: 12px;
     line-height: 13px;
     color: #909090;
  }

.base_cardListModal__3pFpG {
    width: 968px;
    height: 630px;
  }

.base_cardListModalContent__3XbMy {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

.base_cardListModalContentPagination__2rZ40 {
        margin-top: 0px;
        padding-top: 10px;
      }

.base_cardListModalContentBtn__3wvzW {
        text-align: center;
        margin-top: 18px;
      }
.base_migrateSuccess__j7PFP div {
  padding: 15px 0px;
}

@media (max-width: 968px) {
  .base_transfer__2-nQm {
    flex-direction: column;
    padding: 16px 16px;
  }
      .base_transferMigrateBtn__385dX {
        font-size: 14px !important;
      }
    .base_transferBtn__3sKtL {
      font-size: 15px !important;
    }
    .base_transferMenu__3FupF {
      text-align: left;
    }
      .base_transferInfoBlock__3xl62 {
        margin-left: 0px;
        margin-bottom: 20px;
      }
      .base_transferInfoIcon__1j7SL {
        display: none;
      }
    .base_transferInner__15Abq {
      flex-direction: column;
      align-items: flex-start;
    }
    .base_cardListModal__3pFpG {
       width: 80%;
       min-width: 370px;
       height: 664px;
    }
      .base_cardListModalContent__3XbMy {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
        .base_cardListModalContentPagination__2rZ40 {
          margin-top: 36px;
        }
        .base_cardListModalContentBtn__3wvzW {
          text-align: left;
          margin-top: 30px;
        }
}
@media (width: 320px) {
    .base_transferBtn__3sKtL {
       font-size: 15px !important;
       width: 250px !important;
       margin-bottom: 0px !important;
       padding: 0px !important;
       background-color: #eeeeee !important;
       color: #ff6c2d !important;
       border: 1px solid #ff6c2d;
     }
}

.asset_asset__TqfzX {
  /*height: 100%;*/
  margin: 16px 24px;
}
.asset_event__gqCwf {
  font-size: 12px;
  font-weight: 600;
}
.asset_inner__3TT9o {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 100px;
}

.asset_nav__4BOEh {
  display: flex;
}

.asset_back__3Oe4K {
  display: flex;
  align-items: center;
}

.asset_back__3Oe4K:hover,
  .asset_back__3Oe4K:focus {
    color: var(--color-highlight);
  }

.asset_backText__L-eTx {
    margin-left: 8px;
  }

.asset_nextBtn__3P_rw {
  padding-left: 10px;
  justify-content: left;
  background-image: url(/static/media/arrow-right.32f5d866.svg);
  background-position: 90% 50%;
}

.asset_nextBtn__3P_rw:hover {
    background-color: #000;
    color: #fff;
    background-image: url(/static/media/arrow-right-hover.4f88f8ef.svg);
    background-position: 88% 50%;
    background-repeat: no-repeat;
  }

.asset_nextBtn__3P_rw:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url(/static/media/arrow-right-hover.4f88f8ef.svg);
    border: solid 2px #e5e5e5;
  }
.asset_prevBtn__kGt1W {
  padding-right: 10px;
  text-align: right;
  background-image: url(/static/media/arrow-left.a39550f9.svg);
  background-position: 10% 50%;
}
.asset_prevBtn__kGt1W:hover {
    background-color: #000;
    color: #fff;
    background-image: url(/static/media/arrow-left-hover.10fd9885.svg);
    background-position: 10% 50%;
    background-repeat: no-repeat;
  }
.asset_prevBtn__kGt1W:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url(/static/media/arrow-left-hover.10fd9885.svg);
    border: solid 2px #e5e5e5;
  }
.asset_button__WznMy {
  width: 91px;
  height: 30px;
  font-size: 18px;
  border-radius: 15px;
  color: #000;
  background-color: #fff;
  border: solid 2px #000;
}

.asset_content__1xZ5W {
  display: flex;
  margin-top: 30px;
}

.asset_card__wJU_K {
  /*margin-top: 144px;*/
}
.asset_rightView__1wogq {
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
  }
.asset_trading__2ki1i {
}
.asset_info__v5GSZ {
  max-width: 468px;
  min-width: 468px;
  padding: 0 10px;
  flex: 1 1;
  margin: 0 auto;
}
.asset_infoOwner__2Bx1l {
    display: flex;
    align-items: center;
  }
.asset_infoOwnerText__A6ImU {
      font-size: 18px;
    }
.asset_infoOwner__2Bx1l svg {
      fill: none;
      width: 14px;
      height: 14px;
      margin-left: 8px;
    }
.asset_infoOwnerAddress__eHJ4p {
      /*width:50%;*/
      font-size: 14px;
      cursor: pointer;
      text-decoration: underline;
      word-break: break-all;
    }
.asset_infoOwnerAddress__eHJ4p:hover {
        color: #ff6c2d;
      }
.asset_infoHeaderMobile__1_26R {
    display: none;
  }
.asset_infoHeader__sbugC {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
.asset_infoDesc__29PcG {
    font-size: 18px;
    font-weight: 600;
  }
.asset_infoId__1ezcy {
    color: var(--color-highlight);
  }
.asset_infoCaption__2z31J {
    margin: 8px 0px;
    font-size: 18px;
    border-bottom: 0.5px solid #909090;
    font-weight: normal;
  }
.asset_infoCard__3mMrw {
    margin-bottom: 48px;
  }
.asset_infoCardTop__3eUts {
      display: flex;
    }
.asset_infoCardTopRow__1jodn {
        margin-right: 38px;
      }
.asset_infoCardStates__3D9yD {
    margin-bottom: 48px;
    display: flex;
  }
.asset_infoCardStatesText__2uHAQ {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      width: 33%;
    }
.asset_infoCardStatesTextValue__Yfl39 {
        display: flex;
        align-items: center;
        margin-top: 5px;
      }
.asset_infoCardBodyParts__3t06- {
    margin-bottom: 22px;
    display: flex;
  }
.asset_infoCardBodyPartsText__2uHiZ {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      width: 50%;
    }
.asset_infoCardBodyPartsTextValue__2dAda {
        display: flex;
        align-items: center;
        margin-top: 5px;
      }
.asset_infoCardBodyPartsTextValue__2dAda span {
        margin-left: 5px;
      }
.asset_infoSubject__EgUuN {
    font-size: 14px;
    margin-top: 12px;
    color: #909090;
  }
.asset_infoText__3V9F8 {
    font-size: 18px;
    display: flex;
    align-items: center;
  }
.asset_infoCardStatesText__2uHAQ svg,
.asset_infoCardBodyPartsText__2uHiZ svg {
  fill: none;
  width: 28px;
  height: 28px;
}
.asset_infoText__3V9F8 svg {
  fill: none;
  width: 28px;
  height: 28px;
  margin-right: 11px;
}
.asset_infoOwnerAddress__eHJ4p svg {
  fill: none;
  cursor: pointer;
  width: 12px;
  height: 12px;
}
.asset_buy__1gjJl {
  font-size: 14px;
}
.asset_buy__1gjJl:hover {
  }
.asset_buy__1gjJl:focus {
  }
.asset_cancelListingLarge__32p4Q {
    width: 212px !important;
  }
.asset_cancelListing__SDPrb {
    height: 40px;
    width: 96px;
    padding: 0px;
    border-radius: 15px;
    font-size: 18px;
    border: 2px solid #000;
    color: #000 !important;
    background-color: #fff !important;
  }
.asset_cancelListing__SDPrb:hover {
      border: 2px solid #000;
      color: #fff !important;
      background-color: #000 !important;
    }
.asset_checkout__3zYS0 {
  width: 205px;
}
.asset_trade__3aJEp {
  height: 40px;
  width: 212px;
  padding: 0px;
  border-radius: 15px;
  margin-bottom: 10px;
  font-size: 18px;
  border: 2px solid #ff6c2d;
  color: #fff !important;
  background-color: #ff6c2d !important;
}
.asset_trade__3aJEp:hover {
    border: 2px solid #ff6c2d;
    color: #fff !important;
    background-color: #ff6c2d !important;
  }
.asset_trade__3aJEp:focus {
  }
.asset_tradeView__3EQ_C {
    display: inline-flex;
    align-items: center;
    margin-top: 33px;
    flex-direction: column;
    width: 100%;
  }

.asset_activity__Y45cR {
  display: block;
}
.asset_history__3zHFO {
  width: 448px;
}
.asset_buyCard__2hlj8 img {
  margin-bottom: 10px;
}
.asset_buy__1gjJl {
  display: block;
}
.asset_buyContent__2gzA3 {
    margin: 24px 0px;
    border-radius: 4px;
    border-top: none;
  }
.asset_buyItem__o1oT0 {
    padding: 16px 0px;
    display: flex;
    justify-content: space-between;
  }
.asset_buyItem__o1oT0:first-child {
    border-bottom: 1px solid #000000;
  }
.asset_buyName__3LQUH {
    display: flex;
    flex: 1 1;
    align-items: center;
    margin-bottom: 8px;
  }
.asset_buyNameTotal__1MTW_ {
      font-weight: 600;
      font-size: 14px;
      color: #909090;
    }
.asset_buyCard__2hlj8 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 4px;
    background-color: #eeeeee;
  }
.asset_buyCardInfo__1th8R {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 1;
      align-items: center;
    }
.asset_buyCardInfoLeft__2j-oK {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
.asset_buyRace__1aio_ {
    margin: 0 8px;
    margin: 0 8px;
    display: flex;
    align-items: center;
  }
.asset_buyRace__1aio_ svg {
      width: 14px;
      height: 14px;
    }
.asset_buyId__2w4Ms {
    align-items: center;
    display: flex;
    margin: 0 8px;
  }
.asset_buyPrice__1Gydc {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
.asset_buyPriceFlex__20svC {
      display: flex;
      align-items: center;
    }
.asset_buyPriceETH__2UKeg {
      text-align: right;
      font-size: 14px;
      color: #000000;
    }
.asset_buyPriceETHHighlight__TIQLF {
        text-align: right;
        font-size: 18px;
        font-weight: 600;
        color: #ff6c2d;
      }
.asset_buyPriceUSD__2Fuku {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #ff6c2d;
      border: 1px solid #ff6c2d;
      border-radius: 12px;
      height: 13px;
      margin-left: 10px;
      padding: 7px 5px;
    }
.asset_buyPriceUSDNormal__36D59 {
        font-size: 14px;
        color: #ff6c2d;
        border: 0px;
      }
.asset_buyFooter__2bjpl {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
.asset_buyButton__2x6Zo {
    margin: 0 16px;
    font-size: 14px;
  }

.asset_connectWalet__3Ook5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.asset_connectWaletText__25KqO {
    margin: 24px 0;
    text-align: center;
  }

.asset_cancel__12FzA {
  display: block;
}

.asset_cancelContent__2buNl {
    margin: 24px 0;
    border-radius: 4px;
  }

.asset_cancelFooter__3a7MV {
    display: flex;
    justify-content: space-between;
  }

.asset_cancelButton__2HkNx {
    font-size: 14px;
    margin: 0 16px;
    padding: 8px 16px;
  }

.asset_openOutside__1dKaL {
  display: flex;
}
.asset_openOutside__1dKaL svg {
  fill: none;
}
.asset_externalLink__3Aw7z:hover {
    color: #ff6c2d;
  }
/*@media (max-width: 915px) {*/
/*  .info {*/
/*    padding: 0 1%;*/
/*  }*/
/*  .tableHeaderCell,*/
/*  .tableBodyCell {*/
/*    padding: 0px 0px !important;*/
/*  }*/
/*}*/

/*@media (max-width: 1400px) {*/
/*  .info {*/
/*    min-width: 100%;*/
/*    &Header {*/
/*      flex-direction: column;*/
/*    }*/

/*    &Desc {*/
/*      width: 100%;*/
/*    }*/
/*  }*/
/*}*/

@media (max-width: 1254px) {
  .asset_trading__2ki1i {
    margin: 0 auto;
  }
}
@media (max-width: 850px) {
  .asset_content__1xZ5W {
    flex-direction: column;
  }
  .asset_history__3zHFO {
    width: 100%;
  }
    .asset_infoHeader__sbugC {
      display: none;
    }
    .asset_infoHeaderMobile__1_26R {
      display: flex;
    }
      .asset_infoCardTop__3eUts {
        flex-wrap: wrap;
      }
        .asset_infoCardTopRow__1jodn {
          width: 50%;
        }
  .asset_card__wJU_K {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 8px;
  }

  .asset_info__v5GSZ {
    padding: 0;
    margin: 24px 0 0 0;
    max-width: 100%;
    min-width: 100%;
  }
  .asset_asset__TqfzX {
    margin: 16px 16px;
  }
  .asset_trading__2ki1i {
    width: 100%;
    overflow: scroll;
  }
    .asset_infoOwner__2Bx1l {
      align-items: flex-start;
    }
      .asset_infoOwnerAddress__eHJ4p {
        word-break: break-all;
        font-size: 12px;
      }
}

.asset_clear__2rinM {
  margin-bottom: 0px;
}

.activity_activity__1xzGx {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 24px;
}

  .activity_activityListHeaderPrice__1inAQ {
         text-align: right;
         display: flex;
         flex-direction: column;
         flex: 1 1;
         padding-right: 45%;
      }

  .activity_activityListItem__2SrG- {
      text-decoration: none !important;
      display: flex;
      align-items: center;
    }

  .activity_activityListItem__2SrG-:hover {
        color: #000000;
      }

  .activity_activityListItem__2SrG- div {
        width: 40px;
        height: 40px;
        margin-right: 8px;
        background: #F6F8FD;
        border-radius: 4px;
      }

  .activity_activityListItem__2SrG- div img {
          width: 38px;
        }

  .activity_activityListPrice__2ps-J {
       text-align: right;
       display: flex;
       flex-direction: column;
       flex: 1 1;
       padding-right: 45%;
    }

  .activity_activityListPriceETH__2Ocuf {
        font-size: 14px;
        line-height: 14px;
        color: #FF6C2D;
        margin-bottom: 4px;
      }

  .activity_activityListPriceUSD__3SMLz {
        font-size: 12px;
        line-height: 12px;
        color: #909090;
      }

  .activity_activityListDate__24RAm {
      color: #909090;
    }

.activity_select__2u1xO select {
  width: 212px;
  border: 0;
  border-bottom: 1px solid;
}

.activity_event__3bv5U {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
}

.activity_header___k5i5 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0px;
}

.activity_headerInner__F1Z8z {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    max-width: 1078px;
  }

.activity_headerTitle__pOxUP {
    font-weight: 400;
    font-size: 36px;
    line-height: 39px;
  }

.activity_tableList__1CTHJ {
  width: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activity_tableListWrapper__3geY7 {
    min-width: 1078px;
  }

.activity_pagination__jgEhy {
  display: flex;
  justify-content: center;
  margin-top: 45px;
}

.activity_paginationInner__273Tj {
    width: 100%;
    max-width: 1078px;
  }

@media (max-width: 1128px) {
  .activity_tableList__1CTHJ {
    justify-content: flex-start;
  }
}

@media (max-width: 850px) {
  .activity_activity__1xzGx {
    padding: 0 16px;
  }
    .activity_headerInner__F1Z8z {
      flex-direction: column;
    }

  .activity_select__2u1xO select {
    margin-top: 10px;
    width: 100%;
  }
}
.login_login__Hqxje {
  height: 100%;
}

.login_inner__2fk4m {
  max-width: 1200px;
  width: 100%;
  margin: 120px auto 0 auto;
}

.login_title__27rqf {
  text-align: center;
}

.login_metamask__1imZG {
  width: 96px;
  height: 96px;
}

.login_metamaskWrapper__2PdQh {
    margin: 68px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.login_connect__1D-a6 {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marketplace_marketplace__2NQmc {
  /*height: 100%;*/
}
.marketplace_split__2MtsY {
  border-bottom: 0.5px solid #000;
}
.marketplace_splitHeader__1aZJO {
    margin: 0px;
    margin-bottom: 10px;
    border-bottom: 0.5px solid #000;
  }
.marketplace_inner__16JMp {
  margin: 0px 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.marketplace_banner__1AlFa {
  width: 100%;
  margin: 25px 0px;
}
.marketplace_bannerPic__gOVC0 {
     position: relative;
     width: 100%;
     min-height: 320px;
     border-top-left-radius: 15px;
     border-top-right-radius: 15px;
     background-size: 100% auto;
     background-position: 0% 54%;
     overflow: hidden;
  }
.marketplace_bannerPic__gOVC0 div {
       position: absolute;
       left: 5%;
       bottom: 10%;
       font-size: 48px;
       line-height: 52px;
       display: flex;
       align-items: center;
       color: #FFFFFF;
       text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
     }
.marketplace_bannerInfo__3iJBv {
     min-height: 100px;
     display: flex;
     padding: 0px 45px;
     background: #EEEEEE;
     border-bottom-left-radius: 15px;
     border-bottom-right-radius: 15px;
     align-items: center;
     justify-content: space-around;
  }
.marketplace_bannerInfoItem__3PRM- {
      display: flex;
      padding: 30px 0px;
    }
.marketplace_bannerInfoItem__3PRM- svg {
        width: 38px;
        height: 26px;
        fill: none;
      }
.marketplace_bannerInfoItemTitle__3_pOw {
         display: flex;
         align-items: flex-start;
         margin-left: 12px;
         margin-right: 8px;
         font-weight: 500;
         font-size: 14px;
         line-height: 16px;
         color: #909090;
      }
.marketplace_bannerInfoItemTitle__3_pOw svg {
           width: 16px;
           height: 16px;
         }
.marketplace_bannerInfoItemValue__1DNzW {
         font-size: 36px;
         line-height: 32px;
         color: #000000;
         border-bottom: 1px solid #000000;
      }
.marketplace_filter__2-6lG {
  width: 100%;
  border-radius: 4px;
}
.marketplace_filterHeader___hScZ {
    display: flex;
    margin-top: 7px;
    align-items: center;
    justify-content: flex-start;
  }
.marketplace_filterHeaderLeft__3YFKr {
      display: flex;
      width: 100%;
    }
.marketplace_filterTitle__21nRf {
    color: #000;
    font-size: 18px;
    margin-right: 58px;
  }
.marketplace_filterReset__1yzOj {
    color: #909090;
    font-size: 14px;
    text-decoration: underline;
  }
.marketplace_filterResetEnable__1jraw {
      color: #000000;
      text-decoration: underline;
    }
.marketplace_filterBlock__2lLCw {
    margin-right: 109px;
  }
.marketplace_filterCaption__2-SCz {
    margin-top: 12px;
    color: #000;
    font-weight: 600;
  }
.marketplace_filterOptions__mN73b {
    padding: 4px 0px;
    background-color: #fff;
  }
.marketplace_filterCheckbox__2sorB {
    margin-bottom: 18px;
    margin-right: 16px;
  }
.marketplace_filterRadio__vxKOB {
    margin: 8px 0;
  }
.marketplace_filterSlider__1sBzU {
    display: block;
  }
.marketplace_filterRow__3VXDu {
    display: flex;
    justify-content: flex-start;
    padding-right: 10px;
  }

.marketplace_list__1gPdb {
  width: 100%;
  max-width: 1432px;
  flex: 1 1;
  margin: 0 auto;
}

.marketplace_listHeader__slK4V {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }

.marketplace_sum__Rf01N {
  font-family: Archivo;
  font-size: 20px;
}

.marketplace_sort__2yOZR {
  display: flex;
}

.marketplace_select__2xXYL:first-child {
  margin: 0 16px;
}
.marketplace_select__2xXYL select {
  width: 212px;
  border: 0;
  border-bottom: 1px solid;
}

.marketplace_cards__3UqHF {
  display: flex;
  justify-content: center;
  margin: 20px auto;
  flex-wrap: wrap;
  grid-gap: 24px;
  gap: 24px;
}

.marketplace_pagination__1V4cs {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}
.marketplace_marketplace__2NQmc .marketplace_menu__1woaB {
  display: none;
}

@media (min-width: 1600px) {
  .marketplace_list__1gPdb {
    width: 100%;
    max-width: 100%;
    flex: 1 1;
    margin: 0 auto;
  }
}
@media (max-width: 1100px) {
    .marketplace_bannerPic__gOVC0 {
       width: auto;
       min-height: 190px;
    }
       .marketplace_bannerPic__gOVC0 div {
         font-size: 24px;
         line-height: 25px;
       }
    .marketplace_bannerInfo__3iJBv {
      flex-wrap: wrap;
      justify-content: space-between;
    }
      .marketplace_bannerInfoItem__3PRM- {
        min-width: 259px;
      }
}
@media (max-width: 850px) {
  .marketplace_banner__1AlFa {
    margin-top: 0px;
    margin-bottom: 25px;
  }
  .marketplace_open__1UMRV {
    display: flex;
    flex-direction: column;
    padding-right: 0px;
  }
  .marketplace_menu__1woaB {
    display: flex !important;
    width: 32px;
    height: 32px;
    -webkit-user-select: none;
    user-select: none;
    z-index: 1100;
    align-items: center;
    justify-content: flex-end;
  }
  .marketplace_menu__1woaB svg {
    width: 28px;
    height: 28px;
  }
    .marketplace_filterRow__3VXDu {
      display: none;
      flex-direction: column;
      padding-right: 0px;
    }

    .marketplace_filterBlock__2lLCw {
      margin-right: 0px;
      margin-bottom: 15px;
    }
    .marketplace_filterBlock__2lLCw:first-child {
      margin-bottom: 0px;
    }
  .marketplace_sort__2yOZR {
    justify-content: space-between;
  }
  .marketplace_inner__16JMp {
    margin: 16px;
  }
  .marketplace_split__2MtsY {
    margin-top: 15px;
  }
    .marketplace_splitHeader__1aZJO {
      margin: 0px;
      margin-bottom: 10px;
    }
    .marketplace_listHeader__slK4V {
      flex-direction: column;
    }

  .marketplace_select__2xXYL select {
    width: 100%;
  }
  .marketplace_select__2xXYL:first-child {
    margin: 0px;
  }
  .marketplace_select__2xXYL {
    margin: 0px;
    width: 47%;
  }

  .marketplace_cards__3UqHF {
    display: flex;
    justify-content: center;
    margin: 16px auto;
    flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px;
  }
}

@media (max-width: 650px) {
    .marketplace_bannerInfo__3iJBv {
      padding: 0px 15px;
      justify-content: flex-start;
    }
}
@media (max-width: 414px) {
    .marketplace_bannerPic__gOVC0 {
     min-height: 390px;
    }
     .marketplace_bannerPic__gOVC0 div {
       font-weight: 400;
       font-size: 36px;
       line-height: 39px;
     }
}
@media (max-width: 390px) {
    .marketplace_bannerPic__gOVC0 {
       min-height: 320px;
     }
}

.sell_sell__2tQmT {
  margin: 16px 24px;
}

.sell_inner__2myQb {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 100px;
}

.sell_nav__811GR {
  display: flex;
}

.sell_back__1Pq7y {
  display: flex;
  align-items: center;
}

.sell_backText__2Zu9f {
    margin-left: 8px;
  }

.sell_content__2kBDD {
  display: flex;
  margin-top: 30px;
}

.sell_card__1_7O2 {
  /*width:212px;*/
  /*height:256px;*/
}
.sell_cardView__1mjij {
  width: 212px;
  height: 256px;
}
.sell_info__2aoic {
  padding: 0 32px;
  max-width: 768px;
  margin: 0 auto;
}
.sell_infoHeaderMobile__HOkpZ {
    display: none;
  }
.sell_infoHeader__GguWn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
.sell_infoDesc__1t2yY {
    font-size: 18px;
    font-weight: 600;
  }
.sell_infoId__FrmPZ {
    color: var(--color-highlight);
  }
.sell_infoCaption__2sPrc {
    margin: 8px 0px;
    font-size: 18px;
    border-bottom: 0.5px solid #909090;
    font-weight: normal;
  }
.sell_infoCard__1ApJa {
    font-size: 14px;
    margin-top: 12px;
    color: #909090;
  }
.sell_infoSubject__wGiCw {
    font-size: 14px;
    margin-top: 12px;
    color: #909090;
  }
.sell_infoText__16b7E {
    font-size: 14px;
    color: #000;
  }
.sell_infoPrice__1l2_o {
    position: relative;
  }
.sell_infoPrice__1l2_o svg {
      width: 24px;
      height: 24px;
      position: absolute;
      bottom: 17px;
    }

.sell_trade__3BvZe {
  font-size: 18px;
}

.sell_tradeView__H2len {
    display: inline-flex;
    align-items: center;
    margin-top: 33px;
    flex-direction: column;
    width: 100%;
  }

.sell_tradeIcon__1DaKs {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

.sell_button__3dGfV {
  width: 91px;
  height: 30px;
  font-size: 18px;
  border-radius: 15px;
  color: #000;
  background-color: #fff;
  border: solid 2px #000;
}
.sell_prevBtn__32hVB {
  padding-right: 10px;
  text-align: right;
  background-image: url(/static/media/arrow-left.a39550f9.svg);
  background-position: 10% 50%;
}
.sell_prevBtn__32hVB:hover {
    background-color: #000;
    color: #fff;
    background-image: url(/static/media/arrow-left-hover.10fd9885.svg);
    background-position: 10% 50%;
    background-repeat: no-repeat;
  }
.sell_prevBtn__32hVB:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url(/static/media/arrow-left-hover.10fd9885.svg);
    border: solid 2px #e5e5e5;
  }
.sell_price__2xqHa {
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  justify-content: space-between;
}
.sell_priceListedInfo__2SvIS {
    font-size: 14px;
    margin-top: 12px;
    color: #ff6c2d;
    line-height: 15px;
  }
.sell_priceBlock__2YeQm {
    flex: 1 1;
  }
.sell_priceInput__1bojY {
     margin-top: 16px;
     padding: 4px 40px;
  }
.sell_priceInput__1bojY input::-webkit-outer-spin-button,
    .sell_priceInput__1bojY input::-webkit-inner-spin-button {
      -webkit-appearance: revert !important;
              appearance: revert !important;
    }
.sell_priceCard__1ckL9 {
    padding: 16px;
    border: 2px solid var(--color-background-secondary);
    background: linear-gradient(
      329.58deg,
      rgba(87, 65, 52, 0.4) 0%,
      rgba(87, 65, 52, 0) 100%
    );
  }
.sell_priceFees__2U6Bk {
    display: flex;
    margin-top: 16px;
    color: #000;
  }
.sell_priceFeesName__2GL3l {
      width: 200px;
      font-weight: 600;
      font-size: 14px;
    }
.sell_priceFeesValue__29zGl {
      font-size: 14px;
      color: #000;
    }

.sell_steps__2O0JH {
  display: block;
}

.sell_step__3X5IC {
  margin-top: 24px;
}

.sell_step__3X5IC svg {
    fill: none;
    width: 14px;
    height: 14px;
  }

.sell_stepTitle__1sLlp {
    display: flex;
    align-items: center;
    font-size: 18px;
  }

.sell_stepTitleText__JmRND {
      margin-left: 5px;
      font-weight: 600;
      line-height: 15px;
    }

.sell_stepContent__1umhX {
    padding: 5px;
    color: #909090;
  }

.sell_stepSuccess__2MFIQ {
    padding: 5px;
    font-size: 14px;
    color: #ff6c2d;
  }

.sell_done__3zodn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 32px;
}
.sell_card__1_7O2 .sell_card__1_7O2 {
  background-color: red;
}
.sell_sell__2tQmT input {
  width: 100%;
  position: relative;
  border-radius: 0px;
  border-bottom: 1px solid #000;
  color: #909090;
}
.sell_sell__2tQmT input:hover,
  .sell_sell__2tQmT input:focus {
    border-radius: 0px;
    border: 0px;
    border-bottom: 2px solid #ff6c2d;
  }
.sell_sell__2tQmT input:focus {
    border-bottom: 2px solid #ff6c2d;
  }
.sell_sell__2tQmT input::-webkit-outer-spin-button,
.sell_sell__2tQmT input::-webkit-inner-spin-button {
  -webkit-appearance: revert !important;
          appearance: revert !important;
}
.sell_priceContainer__31lof {
  display: flex;
  font-size: 18px;
}
.sell_cardPriceUSD__2kRYj {
  margin-left: 10px;
  color: #ff6c2d;
}
.sell_trading__18hq- {
  max-width: 404px;
  margin-top: 27px;
}

@media screen and (max-width: 768px) {
  .sell_sell__2tQmT input::-webkit-outer-spin-button,
  .sell_sell__2tQmT input::-webkit-inner-spin-button {
    -webkit-appearance: revert !important;
            appearance: revert !important;
  }
  .sell_trading__18hq- {
    width: 100%;
    max-width: 100%;
    margin-bottom: 50px;
  }
  .sell_content__2kBDD {
    flex-direction: column;
  }
  .sell_info__2aoic {
    width: 100%;
    padding: 0px;
  }
    .sell_infoHeader__GguWn {
      display: none;
    }
    .sell_infoHeaderMobile__HOkpZ {
      display: flex;
    }
  .sell_sell__2tQmT {
    margin: 16px 16px;
  }
  .sell_inner__2myQb {
    padding: 0px;
  }
  .sell_card__1_7O2 {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.not-found_notFound__chXXH {
  height: 100%;
}

.not-found_inner__2wcSj {
  max-width: 1200px;
  width: 100%;
  margin: 120px auto 0 auto;
}

.not-found_title__krlRK {
  font-size: 64px;
  text-align: center;
}

:root {
  --color-highlight: #c39175;
  --color-light-primary: #ecdec8;
  --color-light-secondary: #70512e;
  /* --color-background-tertiary: #4d392f;*/
  --color-background-secondary: #574134;
  --color-background-primary: #2f231d;
  --color-disable: #909090;
  --color-link: #c2ad3a;
  --color-warning: #d65a55;
  --color-mask-bg: rgba(0, 0, 0, 0.6);
  --z-index-modal: 1400;
  --z-index-message: 1500;
  --z-index-header: 1300;
  --color-button: #000000;
  --color-button-background: #FFFFFF;
}
.button {
  display: inline-flex;
  align-items: center;
  width: 91px;
  height: 30px;
  font-size: 18px;
  border-radius: 15px;
  color: #000;
  background-color: #fff;
  border: solid 2px #000;
}
.prevBtn {
  padding-right: 10px;
  justify-content: right;
  background-image: url(/static/media/arrow-left.a39550f9.svg);
  background-position: 10% 50%;
}
.prevBtn:hover {
    background-color: #000;
    color: #fff;
    background-image: url(/static/media/arrow-left-hover.10fd9885.svg);
    background-position: 10% 50%;
    background-repeat: no-repeat;
  }
.prevBtn:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url(/static/media/arrow-left-hover.10fd9885.svg);
    border: solid 2px #e5e5e5;
  }
.backBtn {
  text-transform: uppercase;
  width: 212px;
  height: 40px;
  background: #3864FF !important;
  border-radius: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #FFFFFF !important;
  cursor: pointer;
}
.buy,
.trade {
  height: 40px;
  line-height: 40px;
  width: 212px;
  padding: 0px;
  text-align: center;
  border-radius: 15px;
  margin-bottom: 10px;
  font-size: 18px;
  border: 2px solid #ff6c2d;
  color: #fff !important;
  background-color: #ff6c2d !important;
}
.buy:hover, .trade:hover {
    border: 2px solid #ff6c2d;
    color: #fff !important;
    background-color: #ff6c2d !important;
  }
.buy:focus, .trade:focus {
  }
.tradeDisabled {
  height: 40px;
  width: 212px;
  padding: 0px;
  border-radius: 15px;
  margin-bottom: 10px;
  font-size: 18px;
  border: 2px solid #909090;
  color: #909090 !important;
  background-color: #fff !important;
}
input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
            appearance: none;
    margin: 0;
  }

.button-normal {
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  padding: 0px 10px !important;
  background-color: #ffffff !important;
  color: #ff6c2d !important;
  border: 1px solid #ff6c2d;
  cursor: pointer;
}
.fullWidth {
  width: 100% !important;
}



html,
body {
  height: 100%;
  /*color: var(--color-light-primary);*/
  line-height: 1.5;
  font-size: 14px;
  font-family: 'Archivo', 'PingFang SC', 'Microsoft YaHei', 'Helvetica Neue',
    'Hiragino Sans GB', 'WenQuanYi Micro Hei', Arial, sans-serif;
  background-color: #ffffff;
  font-weight: 400;
}

#root {
  height: 100%;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover,
  a:focus {
    color: var(--color-highlight);
  }

/*svg {*/
/*  width: 16px;*/
/*  height: 16px;*/
/*  fill: currentColor;*/
/*}*/

@font-face {
  font-family: 'Chivo';
  src: local('Chivo'),
    url(/static/media/Chivo-Regular.741cf223.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
/*@font-face {*/
/*  font-family: 'Archivo';*/
/*  src: local('Archivo'),*/
/*    url(assets/fonts/archivo/Archivo.ttf) format('truetype');*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  font-display: swap;*/
/*}*/

@font-face {
  font-family: 'Chivo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Chivo'), url(/static/media/Chivo-Bold.673ce0d2.ttf) format('truetype');
}

@font-face {
  font-family: 'Marcellus';
  src: local('Marcellus'),
    url(/static/media/Marcellus-Regular.c29ca9ab.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

