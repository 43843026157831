@import 'ress';
@import 'root.css';
@import 'media.css';
@import 'tippy.js/dist/tippy.css';
@import url('https://fonts.googleapis.com/css?family=Archivo:300,400,500,600,700&display=swap');
html,
body {
  height: 100%;
  /*color: var(--color-light-primary);*/
  line-height: 1.5;
  font-size: 14px;
  font-family: 'Archivo', 'PingFang SC', 'Microsoft YaHei', 'Helvetica Neue',
    'Hiragino Sans GB', 'WenQuanYi Micro Hei', Arial, sans-serif;
  background-color: #ffffff;
  font-weight: 400;
}

#root {
  height: 100%;
}

a {
  color: #000;
  text-decoration: none;

  &:hover,
  &:focus {
    color: var(--color-highlight);
  }
}

/*svg {*/
/*  width: 16px;*/
/*  height: 16px;*/
/*  fill: currentColor;*/
/*}*/

@font-face {
  font-family: 'Chivo';
  src: local('Chivo'),
    url(assets/fonts/chivo/Chivo-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
/*@font-face {*/
/*  font-family: 'Archivo';*/
/*  src: local('Archivo'),*/
/*    url(assets/fonts/archivo/Archivo.ttf) format('truetype');*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  font-display: swap;*/
/*}*/

@font-face {
  font-family: 'Chivo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Chivo'), url(assets/fonts/chivo/Chivo-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Marcellus';
  src: local('Marcellus'),
    url(assets/fonts/marcellus/Marcellus-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
