.asset {
  /*height: 100%;*/
  margin: 16px 24px;
}
.event {
  font-size: 12px;
  font-weight: 600;
}
.inner {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 100px;
}

.nav {
  display: flex;
}

.back {
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    color: var(--color-highlight);
  }

  &Text {
    margin-left: 8px;
  }
}

.nextBtn {
  padding-left: 10px;
  justify-content: left;
  background-image: url('/src/assets/icons/arrow-right.svg');
  background-position: 90% 50%;
  &:hover {
    background-color: #000;
    color: #fff;
    background-image: url('/src/assets/icons/arrow-right-hover.svg');
    background-position: 88% 50%;
    background-repeat: no-repeat;
  }
  &:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url('/src/assets/icons/arrow-right-hover.svg');
    border: solid 2px #e5e5e5;
  }
}
.prevBtn {
  padding-right: 10px;
  text-align: right;
  background-image: url('/src/assets/icons/arrow-left.svg');
  background-position: 10% 50%;
  &:hover {
    background-color: #000;
    color: #fff;
    background-image: url('/src/assets/icons/arrow-left-hover.svg');
    background-position: 10% 50%;
    background-repeat: no-repeat;
  }
  &:disabled {
    color: #fff;
    background-color: #e5e5e5;
    background-image: url('/src/assets/icons/arrow-left-hover.svg');
    border: solid 2px #e5e5e5;
  }
}
.button {
  width: 91px;
  height: 30px;
  font-size: 18px;
  border-radius: 15px;
  color: #000;
  background-color: #fff;
  border: solid 2px #000;
}

.content {
  display: flex;
  margin-top: 30px;
}

.card {
  /*margin-top: 144px;*/
}
.right {
  &View {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
  }
}
.trading {
}
.info {
  max-width: 468px;
  min-width: 468px;
  padding: 0 10px;
  flex: 1;
  margin: 0 auto;
  &Owner {
    display: flex;
    align-items: center;
    &Text {
      font-size: 18px;
    }
    svg {
      fill: none;
      width: 14px;
      height: 14px;
      margin-left: 8px;
    }
    &Address {
      /*width:50%;*/
      font-size: 14px;
      cursor: pointer;
      text-decoration: underline;
      word-break: break-all;
      &:hover {
        color: #ff6c2d;
      }
    }
  }
  &HeaderMobile {
    display: none;
  }
  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Desc {
    font-size: 18px;
    font-weight: 600;
  }

  &Id {
    color: var(--color-highlight);
  }

  &Caption {
    margin: 8px 0px;
    font-size: 18px;
    border-bottom: 0.5px solid #909090;
    font-weight: normal;
  }
  &Card {
    margin-bottom: 48px;
    &Top {
      display: flex;
      &Row {
        margin-right: 38px;
      }
    }
  }
  &CardStates {
    margin-bottom: 48px;
    display: flex;
    &Text {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      width: 33%;
      &Value {
        display: flex;
        align-items: center;
        margin-top: 5px;
      }
    }
  }
  &CardBodyParts {
    margin-bottom: 22px;
    display: flex;
    &Text {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      width: 50%;
      &Value {
        display: flex;
        align-items: center;
        margin-top: 5px;
      }
      &Value span {
        margin-left: 5px;
      }
    }
  }

  &Subject {
    font-size: 14px;
    margin-top: 12px;
    color: #909090;
  }

  &Text {
    font-size: 18px;
    display: flex;
    align-items: center;
  }
}
.infoCardStatesText svg,
.infoCardBodyPartsText svg {
  fill: none;
  width: 28px;
  height: 28px;
}
.infoText svg {
  fill: none;
  width: 28px;
  height: 28px;
  margin-right: 11px;
}
.infoOwnerAddress svg {
  fill: none;
  cursor: pointer;
  width: 12px;
  height: 12px;
}
.buy {
  font-size: 14px;
  &:hover {
  }
  &:focus {
  }
}
.cancel {
  &ListingLarge {
    width: 212px !important;
  }
  &Listing {
    height: 40px;
    width: 96px;
    padding: 0px;
    border-radius: 15px;
    font-size: 18px;
    border: 2px solid #000;
    color: #000 !important;
    background-color: #fff !important;
    &:hover {
      border: 2px solid #000;
      color: #fff !important;
      background-color: #000 !important;
    }
  }
}
.checkout {
  width: 205px;
}
.trade {
  height: 40px;
  width: 212px;
  padding: 0px;
  border-radius: 15px;
  margin-bottom: 10px;
  font-size: 18px;
  border: 2px solid #ff6c2d;
  color: #fff !important;
  background-color: #ff6c2d !important;
  &:hover {
    border: 2px solid #ff6c2d;
    color: #fff !important;
    background-color: #ff6c2d !important;
  }
  &:focus {
  }
  &View {
    display: inline-flex;
    align-items: center;
    margin-top: 33px;
    flex-direction: column;
    width: 100%;
  }
}

.activity {
  display: block;
}
.history {
  width: 448px;
}
.buyCard img {
  margin-bottom: 10px;
}
.buy {
  display: block;

  &Content {
    margin: 24px 0px;
    border-radius: 4px;
    border-top: none;
  }

  &Item {
    padding: 16px 0px;
    display: flex;
    justify-content: space-between;
  }
  &Item:first-child {
    border-bottom: 1px solid #000000;
  }

  &Name {
    display: flex;
    flex: 1;
    align-items: center;
    margin-bottom: 8px;
    &Total {
      font-weight: 600;
      font-size: 14px;
      color: #909090;
    }
  }
  &Card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 4px;
    background-color: #eeeeee;

    &Info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 1;
      align-items: center;
      &Left {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
  &Race {
    margin: 0 8px;
    margin: 0 8px;
    display: flex;
    align-items: center;
    svg {
      width: 14px;
      height: 14px;
    }
  }
  &Id {
    align-items: center;
    display: flex;
    margin: 0 8px;
  }

  &Price {
    display: flex;
    width: 100%;
    justify-content: space-between;
    &Flex {
      display: flex;
      align-items: center;
    }
    &ETH {
      text-align: right;
      font-size: 14px;
      color: #000000;
      &Highlight {
        text-align: right;
        font-size: 18px;
        font-weight: 600;
        color: #ff6c2d;
      }
    }

    &USD {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #ff6c2d;
      border: 1px solid #ff6c2d;
      border-radius: 12px;
      height: 13px;
      margin-left: 10px;
      padding: 7px 5px;
      &Normal {
        font-size: 14px;
        color: #ff6c2d;
        border: 0px;
      }
    }
  }

  &Footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &Button {
    margin: 0 16px;
    font-size: 14px;
  }
}

.connectWalet {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &Text {
    margin: 24px 0;
    text-align: center;
  }
}

.cancel {
  display: block;

  &Content {
    margin: 24px 0;
    border-radius: 4px;
  }

  &Footer {
    display: flex;
    justify-content: space-between;
  }

  &Button {
    font-size: 14px;
    margin: 0 16px;
    padding: 8px 16px;
  }
}

.openOutside {
  display: flex;
}
.openOutside svg {
  fill: none;
}
.externalLink {
  &:hover {
    color: #ff6c2d;
  }
}
/*@media (max-width: 915px) {*/
/*  .info {*/
/*    padding: 0 1%;*/
/*  }*/
/*  .tableHeaderCell,*/
/*  .tableBodyCell {*/
/*    padding: 0px 0px !important;*/
/*  }*/
/*}*/

/*@media (max-width: 1400px) {*/
/*  .info {*/
/*    min-width: 100%;*/
/*    &Header {*/
/*      flex-direction: column;*/
/*    }*/

/*    &Desc {*/
/*      width: 100%;*/
/*    }*/
/*  }*/
/*}*/

@media (max-width: 1254px) {
  .trading {
    margin: 0 auto;
  }
}
@media (max-width: 850px) {
  .content {
    flex-direction: column;
  }
  .history {
    width: 100%;
  }
  .info {
    &Header {
      display: none;
    }
    &HeaderMobile {
      display: flex;
    }
    &Card {
      &Top {
        flex-wrap: wrap;
        &Row {
          width: 50%;
        }
      }
    }
  }
  .card {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 8px;
  }

  .info {
    padding: 0;
    margin: 24px 0 0 0;
    max-width: 100%;
    min-width: 100%;
  }
  .asset {
    margin: 16px 16px;
  }
  .trading {
    width: 100%;
    overflow: scroll;
  }
  .info {
    &Owner {
      align-items: flex-start;
      &Address {
        word-break: break-all;
        font-size: 12px;
      }
    }
  }
}

.clear {
  margin-bottom: 0px;
}
